import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import { useToast } from '../contexts/ToastContext';
import { useLoadScript } from '@react-google-maps/api';
import GoogleAutocomplete from '../components/GoogleAutocomplete';
import config from '../config';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import './NewWorkOrder.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext

const NewWorkOrder = () => {
  const { translations } = useContext(LanguageContext); // Bruker oversettelser fra LanguageContext
  const [formData, setFormData] = useState({
    customerName: '',
    extCustomerNo: '',
    category: '1', // Default selected category
    routeArea: '', // New field for route area
    contact: '',
    email: '',
    telephone: '',
    streetAddress: '',
    aptNo: '',
    postal: '',
    city: ''
  });
  const [categories, setCategories] = useState([]);
  const [routeAreas, setRouteAreas] = useState([]);
  const [touched, setTouched] = useState({});
  const [isBlocking, setIsBlocking] = useState(false);
  const navigate = useNavigate();
  const notify = useToast();

  // Initialize Google Maps Autocomplete
  const libraries = useMemo(() => ['places'], []);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
    id: 'google-maps-script',
  });

  useEffect(() => {
    const isFormDirty = Object.values(formData).some(value => value);
    setIsBlocking(isFormDirty);

    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = translations.leavePageWarning || "All entered data will be lost. Are you sure you want to leave this page?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formData, translations]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      apiClient.get(`${config.apiUrl}/getCustomerCategory/?token=${token}`)
        .then(response => {
          const data = response.data;
          if (data.status === 'success') {
            setCategories(data.data);
          } else {
            notify('error', translations.failedToLoadCategories || 'Failed to load categories');
          }
        })
        .catch(error => {
          notify('error', translations.errorLoadingCategories || 'An error occurred while loading categories');
        });

      apiClient.get(`${config.apiUrl}/getRouteAreas/?token=${token}`)
        .then(response => {
          const data = response.data;
          if (data.status === 'success') {
            setRouteAreas(data.data);
          } else {
            notify('error', translations.failedToLoadRouteAreas || 'Failed to load route areas');
          }
        })
        .catch(error => {
          notify('error', translations.errorLoadingRouteAreas || 'An error occurred while loading route areas');
        });
    } else {
      notify('error', translations.noTokenFound || 'No token found');
    }
  }, [notify, translations]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setTouched({
      ...touched,
      [name]: true
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'customerName' && value.trim() !== '') {
      setFormData((prevData) => ({
        ...prevData,
        contact: value
      }));
      setTouched((prevTouched) => ({
        ...prevTouched,
        contact: true
      }));
    }
  };

  const handleBack = () => {
    if (Object.values(formData).some(value => value)) {
      if (window.confirm(translations.leavePageWarning || 'All entered data will be lost. Are you sure you want to go back?')) {
        navigate('/mainmenu');
      }
    } else {
      navigate('/mainmenu');
    }
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const isFieldValid = (name, value) => {
    if (name === 'email') {
      return validateEmail(value);
    }
    return value.trim() !== '';
  };

  const isRequired = (name) => {
    const requiredFields = ['customerName', 'contact', 'email', 'streetAddress', 'postal', 'city', 'routeArea'];
    return requiredFields.includes(name);
  };

  const handleNext = () => {
    const invalidFields = Object.keys(formData).filter(
      name => isRequired(name) && !isFieldValid(name, formData[name])
    );

    if (invalidFields.length > 0) {
      notify('error', `${translations.requiredFieldsMessage || 'The following fields are required'}: ${invalidFields.join(', ')}`);
    } else {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userid'); // Hent user_id fra localStorage
      if (token && userId) {
        const payload = {
          customer: formData.customerName,
          street_address: formData.streetAddress,
          postal: formData.postal,
          city: formData.city,
          phone: formData.telephone,
          email: formData.email,
          contact: formData.contact,
          ext_cust_no: formData.extCustomerNo,
          unit_number: formData.aptNo,
          rute_omrade_id: parseInt(formData.routeArea, 10),
          customer_cat_id: parseInt(formData.category, 10),
          user_id: parseInt(userId, 10) // Legg til user_id i payload
        };

        apiClient.post(`${config.apiUrl}/addCustomerAndAgreement/?token=${token}`, payload)
          .then(response => {
            const data = response.data;
            if (data.status === 'success') {
              notify('success', translations.formSubmittedSuccessfully || 'Form submitted successfully');
              console.log('Response:', data);
              // Naviger til ny side med behandlingsadr_id og oppdrag_id
              const { behandlingsadr_id, oppdrag_id } = data.data;
              navigate(`/legacy/createreport/${behandlingsadr_id}/${oppdrag_id}`);
            } else {
              notify('error', translations.failedToSubmitForm || 'Failed to submit form');
            }
          })
          .catch(error => {
            notify('error', translations.errorSubmittingForm || 'An error occurred while submitting the form');
          });
      } else {
        notify('error', translations.noTokenOrUserIdFound || 'No token or user ID found');
      }
    }
  };

  const handlePlaceChanged = (address) => {
    setTouched((prevTouched) => ({
      ...prevTouched,
      streetAddress: true,
      city: true,
      postal: true,
    }));
  };

  if (loadError) {
    return <div>{translations.mapsLoadingError || 'Error loading maps'}</div>;
  }

  if (!isLoaded) {
    return <div>{translations.loadingMaps || 'Loading...'}</div>;
  }

  return (
    <MainLayout>
      <div className="nwo-form-container">
        <h1>{translations.newWorkOrder || 'New Work Order'}</h1>
        <div className="nwo-form-group">
          <label>{translations.customerName || 'Customer Name'}</label>
          <input
            type="text"
            name="customerName"
            placeholder={translations.enterCustomerName || 'Enter customer name'}
            value={formData.customerName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              touched.customerName
                ? isFieldValid('customerName', formData.customerName)
                  ? 'nwo-valid'
                  : 'nwo-invalid'
                : isRequired('customerName') ? 'nwo-required' : ''
            }
          />
        </div>
        <div className="nwo-form-group">
          <label>{translations.extCustomerNo || 'Ext. Customer No'}</label>
          <input
            type="text"
            name="extCustomerNo"
            placeholder={translations.enterExtCustomerNo || 'Enter external customer number'}
            value={formData.extCustomerNo}
            onChange={handleChange}
            className="nwo-non-mandatory"
          />
        </div>
        <div className="nwo-form-group">
          <label>{translations.selectCategory || 'Select Category'}</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="nwo-non-mandatory"
          >
            {categories.map(category => (
              <option key={category.customer_cat_id} value={category.customer_cat_id}>
                {category.customer_cat}
              </option>
            ))}
          </select>
        </div>
        <div className="nwo-form-group">
          <label>{translations.selectRouteArea || 'Select Route Area'}</label>
          <select
            name="routeArea"
            value={formData.routeArea}
            onChange={handleChange}
            className={
              touched.routeArea
                ? isFieldValid('routeArea', formData.routeArea)
                  ? 'nwo-valid'
                  : 'nwo-invalid'
                : isRequired('routeArea') ? 'nwo-required' : ''
            }
          >
            <option value="">{translations.selectRouteArea || 'Select Route Area'}</option>
            {routeAreas.map(area => (
              <option key={area.rute_omrade_id} value={area.rute_omrade_id}>
                {area.omrade}
              </option>
            ))}
          </select>
        </div>
        <div className="nwo-form-group">
          <label>{translations.contact || 'Contact'}</label>
          <input
            type="text"
            name="contact"
            placeholder={translations.enterContact || 'Enter contact name'}
            value={formData.contact}
            onChange={handleChange}
            className={
              touched.contact
                ? isFieldValid('contact', formData.contact)
                  ? 'nwo-valid'
                  : 'nwo-invalid'
                : isRequired('contact') ? 'nwo-required' : ''
            }
          />
        </div>
        <div className="nwo-form-group">
          <label>{translations.email || 'E-mail Address'}</label>
          <input
            type="email"
            name="email"
            placeholder={translations.enterEmail || 'Enter e-mail address'}
            value={formData.email}
            onChange={handleChange}
            className={
              touched.email
                ? isFieldValid('email', formData.email)
                  ? 'nwo-valid'
                  : 'nwo-invalid'
                : isRequired('email') ? 'nwo-required' : ''
            }
          />
        </div>
        <div className="nwo-form-group">
          <label>{translations.telephone || 'Telephone'}</label>
          <input
            type="text"
            name="telephone"
            placeholder={translations.enterTelephone || 'Enter telephone number (optional)'}
            value={formData.telephone}
            onChange={handleChange}
          />
        </div>
        <div className="nwo-form-group nwo-small-group">
          <label>{translations.streetAddress || 'Street Address'}</label>
          <GoogleAutocomplete
            onPlaceChanged={handlePlaceChanged}
            setFormData={setFormData}
            formData={formData}
          />
          <label>{translations.aptNo || 'Apt No'}</label>
          <input
            type="text"
            name="aptNo"
            placeholder={translations.enterAptNo || 'Enter apartment number (optional)'}
            value={formData.aptNo}
            onChange={handleChange}
            className="nwo-non-mandatory"
          />
        </div>
        <div className="nwo-form-group nwo-small-group">
          <label>{translations.postal || 'Postal/Zip'}</label>
          <input
            type="text"
            name="postal"
            placeholder={translations.enterPostal || 'Enter postal/zip code'}
            value={formData.postal}
            onChange={handleChange}
            className={
              touched.postal
                ? isFieldValid('postal', formData.postal)
                  ? 'nwo-valid'
                  : 'nwo-invalid'
                : isRequired('postal') ? 'nwo-required' : ''
            }
          />
          <label>{translations.city || 'City'}</label>
          <input
            type="text"
            name="city"
            placeholder={translations.enterCity || 'Enter city'}
            value={formData.city}
            onChange={handleChange}
            className={
              touched.city
                ? isFieldValid('city', formData.city)
                  ? 'nwo-valid'
                  : 'nwo-invalid'
                : isRequired('city') ? 'nwo-required' : ''
            }
          />
        </div>
        <div className="nwo-button-group">
          <button className="nwo-cancel-button" onClick={handleBack}>{translations.cancel || 'Cancel'}</button>
          <button className="nwo-next-button" onClick={handleNext}>{translations.next || 'Next'}</button>
        </div>
      </div>
    </MainLayout>
  );
};

export default NewWorkOrder;

import React, { useEffect, useState, useCallback, useContext } from 'react';
import { GoogleMap, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext

// Definer libraries som en konstant utenfor komponenten
const libraries = ['places'];

const OptimalRoute = ({ addresses, setSortedAddresses, setDistances }) => {
  const { translations } = useContext(LanguageContext); // Hent oversettelser fra LanguageContext
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries: libraries,
  });

  const [directions, setDirections] = useState(null);
  const [localSortedAddresses, setLocalSortedAddresses] = useState([]);
  const [localDistances, setLocalDistances] = useState({});

  const fetchDirections = useCallback(() => {
    if (!window.google) {
      console.error('Google Maps JavaScript API not loaded.');
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    
    const start_position = localStorage.getItem('start_position');
    const end_position = localStorage.getItem('end_position');

    if (!start_position || !end_position) {
      alert(translations['startEndPositionsRequired'] || "Start and end positions must be set in your profile.");
      return;
    }

    const origin = start_position;
    const destination = end_position;

    const uniqueAddresses = addresses.reduce((acc, curr) => {
      const duplicate = acc.find(address => 
        address.behandlingsadr === curr.behandlingsadr &&
        address.postnr === curr.postnr &&
        address.poststed === curr.poststed
      );
      
      if (!duplicate) {
        acc.push(curr);
      } else {
        if (curr.navn < duplicate.navn) {
          acc = acc.filter(item => item !== duplicate);
          acc.push(curr);
        }
      }
      return acc;
    }, []);

    const waypoints = uniqueAddresses.map(address => ({
      location: `${address.behandlingsadr}, ${address.postnr} ${address.poststed}`,
      stopover: true
    }));

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);

          const sortedOrder = [
            { behandlingsadr: start_position, isStart: true },
            ...result.routes[0].waypoint_order.map(index => uniqueAddresses[index]),
            { behandlingsadr: end_position, isEnd: true }
          ];
          setLocalSortedAddresses(sortedOrder);
          setSortedAddresses(sortedOrder);

          const distances = {};
          result.routes[0].legs.forEach((leg, index) => {
            const address = sortedOrder[index];
            if (address && address.behandlingsadr_id) {
              distances[address.behandlingsadr_id] = {
                distance: leg.distance.text,
                duration: leg.duration.text
              };
            }
          });

          setLocalDistances(distances);
          setDistances(distances);
        } else {
          console.error(`Error fetching directions: ${result}`);
          alert(translations['routeCalculationFailed'] || 'Could not calculate route. This is likely due to poor address quality in the route. Please choose another sorting option.');
        }
      }
    );
  }, [addresses, setDistances, setSortedAddresses, translations]);

  useEffect(() => {
    // Beregn ruten kun én gang når komponenten laster
    if (isLoaded && !directions) {
      fetchDirections();
    }
  }, [isLoaded, directions, fetchDirections]);

  if (loadError) {
    return <div>{translations['errorLoadingMaps'] || 'Error loading Google Maps'}</div>;
  }

  if (!isLoaded) {
    return <div>{translations['loadingMaps'] || 'Loading...'}</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{ height: '400px', width: '100%' }}
        center={{ lat: -3.745, lng: -38.523 }}
        zoom={3}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
      <div className="address-cards-container">
        {localSortedAddresses.map((address, index) => (
          <div key={index} className="address-card">
            <p><strong>{address.isStart ? translations['start'] || 'Start' : address.isEnd ? translations['end'] || 'End' : address.navn}</strong></p>
            <p>{address.behandlingsadr}</p>
            <p>{address.postnr} {address.poststed}</p>
            <p>{address.behandling}</p>
            {localDistances[address.behandlingsadr_id] && (
              <div className="distance-info">
                <p>{localDistances[address.behandlingsadr_id].distance} - {localDistances[address.behandlingsadr_id].duration}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OptimalRoute;

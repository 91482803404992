import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import './NewInspection.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext

const NewInspection = () => {
  const { translations } = useContext(LanguageContext); // Bruk translations fra LanguageContext
  
  // Hent inspectionId fra URL-parametere
  const { inspectionId } = useParams();

  // Definer state-variabler for å holde form input-verdier
  const [customerName, setCustomerName] = useState('');
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [project, setProject] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [aptNumber, setAptNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  
  // Initialize navigate funksjon fra react-router-dom for navigasjon mellom sider
  const navigate = useNavigate();

  // Bruk useMemo for å memoize libraries array, som brukes for å laste Google Maps script
  const libraries = useMemo(() => ['places'], []);

  // Last Google Maps script med den spesifiserte API-nøkkelen og libraries
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
    id: 'google-maps-script',
  });

  // Hent token og technician_id fra localStorage
  const token = localStorage.getItem('token');
  const technician_id = parseInt(localStorage.getItem('userid'), 10);

  // Opprett en ref for å holde Autocomplete-instanse
  const autocompleteRef = useRef(null);

  // Hent inspeksjonstyper når komponenten monteres
  useEffect(() => {
    apiClient.get(`${config.apiUrl}/getInsType/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setInspectionTypes(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching inspection types:', error);
      });
  }, [token]);

  // Hent inspeksjonsdetaljer hvis inspectionId er til stede
  useEffect(() => {
    if (inspectionId) {
      apiClient.get(`${config.apiUrl}/getInspectionNew/${inspectionId}/?token=${token}`)
        .then(response => {
          if (response.data.status === 'success') {
            const inspection = response.data.data;
            // Populer state-variabler med de hentede inspeksjonsdetaljene
            setCustomerName(inspection.customer_name);
            setSelectedType(inspection.inspection_type_id);
            setContact(inspection.contact_name);
            setEmail(inspection.mail);
            setTelephone(inspection.phone);
            setStreetAddress(inspection.inspection_addr);
            setAptNumber(inspection.unit_number);
            setPostalCode(inspection.postal_code);
            setCity(inspection.city);
          }
        })
        .catch(error => {
          console.error('Error fetching inspection:', error);
        });
    }
  }, [inspectionId, token]);

  // Håndter adresseendringer ved hjelp av Google Maps Autocomplete
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place) {
      const addressComponents = place.address_components;
      const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
      const postalCode = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
      const city = addressComponents.find(comp => comp.types.includes('postal_town'))?.long_name || '';

      setStreetAddress(`${route} ${streetNumber}`);
      setPostalCode(postalCode);
      setCity(city);
    }
  };

  // Håndter "Next"-knappen
  const handleNext = () => {
    const payload = {
      date: new Date().toISOString(),
      customer_name: customerName,
      inspection_addr: streetAddress,
      postal_code: postalCode,
      city: city,
      phone: telephone,
      mail: email,
      contact_name: contact,
      technician_id: technician_id,
      inspection_type_id: selectedType,
      unit_number: aptNumber,
      inspection_id: inspectionId
    };

    if (inspectionId) {
      apiClient.post(`${config.apiUrl}/updInspectionNew/?token=${token}`, payload)
        .then(response => {
          if (response.data.status === 'success') {
            navigate(`/inspectiondetails/${inspectionId}`);
          }
        })
        .catch(error => {
          console.error('Error updating inspection:', error);
        });
    } else {
      apiClient.post(`${config.apiUrl}/addInspectionNew/?token=${token}`, payload)
        .then(response => {
          if (response.data.status === 'success') {
            navigate(`/inspectiondetails/${response.data.inspection_id}`);
          }
        })
        .catch(error => {
          console.error('Error adding inspection:', error);
        });
    }
  };

  // Håndter "Cancel"-knappen
  const handleCancel = () => {
    if (inspectionId) {
      if (window.confirm(translations.inspectionDeleteConfirm || 'The inspection will be deleted if you navigate away. Do you want to proceed?')) {
        apiClient.delete(`${config.apiUrl}/delInspectionNew/${inspectionId}/?token=${token}`)
          .then(response => {
            if (response.data.status === 'success') {
              navigate('/mainmenu');
            }
          })
          .catch(error => {
            console.error('Error deleting inspection:', error);
          });
      }
    } else {
      navigate('/mainmenu');
    }
  };

  // Håndter loading-feil for Google Maps script
  if (loadError) {
    return <div>{translations.errorLoadingMaps || 'Error loading maps'}</div>;
  }

  // Vis loading-melding til Google Maps script er lastet
  if (!isLoaded) {
    return <div>{translations.loading || 'Loading...'}</div>;
  }

  return (
    <MainLayout title={translations.newInspection || "New Inspection"}>
      <div className="ins-container">
        <div className="ins-field">
          <label>{translations.customerName || 'Customer Name'}</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className={`ins-input ${!customerName ? 'ins-input-required' : 'ins-input-filled'}`}
          />
        </div>
        <div className="ins-field">
          <label>{translations.chooseType || 'Choose Type'}</label>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className={`select-input ${!selectedType ? 'ins-input-required' : 'ins-input-filled'}`}
          >
            <option value="">{translations.selectType || 'Select Type'}</option>
            {inspectionTypes.map(type => (
              <option key={type.inspection_type_id} value={type.inspection_type_id}>{type.type}</option>
            ))}
          </select>
        </div>
        <div className="ins-field">
          <label>{translations.project || 'Project'}</label>
          <input
            type="text"
            value={project}
            onChange={(e) => setProject(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field">
          <label>{translations.contact || 'Contact'}</label>
          <input
            type="text"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field">
          <label>{translations.email || 'E-mail Address'}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field">
          <label>{translations.telephone || 'Telephone'}</label>
          <input
            type="tel"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field-inline">
          <div className="ins-field ins-flex-2">
            <label>{translations.streetAddress || 'Street Address'}</label>
            <Autocomplete
              onLoad={(ref) => (autocompleteRef.current = ref)}
              onPlaceChanged={handlePlaceChanged}
              options={{ componentRestrictions: { country: 'no' } }}
            >
              <input
                type="text"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                className={`ins-input ${!streetAddress ? 'ins-input-required' : 'ins-input-filled'}`}
              />
            </Autocomplete>
          </div>
          <div className="ins-field ins-flex-1">
            <label>{translations.aptNo || 'Apt No'}</label>
            <input
              type="text"
              value={aptNumber}
              onChange={(e) => setAptNumber(e.target.value)}
              className="ins-input"
            />
          </div>
        </div>
        <div className="ins-field-inline">
          <div className="ins-field ins-flex-1">
            <label>{translations.postalCode || 'Postal/Zip'}</label>
            <input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className={`ins-input ${!postalCode ? 'ins-input-required' : 'ins-input-filled'}`}
            />
          </div>
          <div className="ins-field ins-flex-2">
            <label>{translations.city || 'City'}</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className={`ins-input ${!city ? 'ins-input-required' : 'ins-input-filled'}`}
            />
          </div>
        </div>
        <div className="ins-buttons">
          <button className="ins-button ins-button-cancel" onClick={handleCancel}>{translations.cancel || 'Cancel'}</button>
          <button className="ins-button ins-button-next" onClick={handleNext}>{translations.next || 'Next'}</button>
        </div>
        {inspectionId && (
          <div className="inspection-id">
            <p>{translations.inspectionId || 'Inspection ID'}: {inspectionId}</p>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default NewInspection;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient';
import MainLayout from '../components/MainLayout';
import config from '../config';
import { useToast } from '../contexts/ToastContext';
import './AgreementNewTreat.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext

const AgreementNewTreat = () => {
  const { translations } = React.useContext(LanguageContext); // Use translations from LanguageContext
  const navigate = useNavigate();
  const { kundeId, avtaleId, behandlingsadrId } = useParams();
  const notify = useToast();
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [availableTreatments, setAvailableTreatments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (behandlingsadrId) {
      sessionStorage.setItem('previousPath', `/serviceaddress-new/${avtaleId}/${kundeId}/${behandlingsadrId}`);
    } else {
      sessionStorage.setItem('previousPath', `/serviceaddress-new/${avtaleId}/${kundeId}`);
    }

    const fetchTreatments = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getTreatment/?token=${token}`);
        if (response.data.status === 'success') {
          setAvailableTreatments(response.data.data);
        } else {
          console.error('Failed to fetch treatments');
          notify('error', translations['fetchTreatmentsError'] || 'An error occurred while fetching treatments.');
        }
      } catch (error) {
        console.error('An error occurred while fetching treatments:', error);
        notify('error', translations['fetchTreatmentsError'] || 'An error occurred while fetching treatments.');
      }
    };

    const fetchSelectedTreatments = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getAvtaleBeh/${avtaleId}/?token=${token}`);
        if (response.data.status === 'success') {
          const fetchedTreatments = response.data.data.map(treatment => ({
            ...treatment,
            extra_sanering: treatment.extra_sanering || false,
            reservice: treatment.reservice || 0,
            reservice_unl: treatment.reservice_unl || false,
            existing: true
          }));
          setSelectedTreatments(fetchedTreatments);
        } else {
          console.error('Failed to fetch selected treatments');
        }
      } catch (error) {
        console.error('An error occurred while fetching selected treatments:', error);
        notify('error', translations['fetchSelectedTreatmentsError'] || 'An error occurred while fetching selected treatments.');
      } finally {
        setLoading(false);
      }
    };

    fetchTreatments();
    fetchSelectedTreatments();
  }, [kundeId, avtaleId, behandlingsadrId, notify, translations]);

  const handleTreatmentSelect = (e) => {
    const treatmentId = e.target.value;
    const treatment = availableTreatments.find(t => t.behandling_id === parseInt(treatmentId, 10));
    if (treatment) {
      setSelectedTreatments([...selectedTreatments, {
        ...treatment,
        extra_sanering: false,
        reservice: 0,
        reservice_unl: false,
        existing: false
      }]);
    }
  };

  const handleTreatmentChange = (index, key, value) => {
    const updatedTreatments = [...selectedTreatments];
    updatedTreatments[index][key] = value;
    if (key === 'reservice_unl' && value) {
      updatedTreatments[index].reservice = 0;
    }
    setSelectedTreatments(updatedTreatments);
  };

  const handleRemoveTreatment = async (index) => {
    const treatment = selectedTreatments[index];
    if (treatment.existing) {
      const confirmDelete = window.confirm(translations['confirmDeleteTreatment']?.replace('{treatment}', treatment.behandling) || `Are you sure you want to delete the treatment: ${treatment.behandling}?`);
      if (confirmDelete) {
        const token = localStorage.getItem('token');
        try {
          const response = await apiClient.delete(`${config.apiUrl}/delXsanering/?token=${token}`, {
            data: { behandling_id: treatment.behandling_id, avtale_id: avtaleId }
          });
          if (response.data.status === 'success') {
            const updatedTreatments = selectedTreatments.filter((_, i) => i !== index);
            setSelectedTreatments(updatedTreatments);
            notify('success', translations['treatmentDeletedSuccess']?.replace('{treatment}', treatment.behandling) || `Treatment ${treatment.behandling} deleted successfully!`);
          } else {
            console.error('Failed to delete treatment:', treatment.behandling);
          }
        } catch (error) {
          console.error('An error occurred while deleting treatment:', error);
          notify('error', translations['deleteTreatmentError'] || 'An error occurred while deleting the treatment.');
        }
      }
    } else {
      const updatedTreatments = selectedTreatments.filter((_, i) => i !== index);
      setSelectedTreatments(updatedTreatments);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      for (const treatment of selectedTreatments) {
        const payload = {
          behandling_id: treatment.behandling_id,
          avtale_id: avtaleId,
          extra_sanering: treatment.extra_sanering,
          reservice: treatment.reservice,
          reservice_unl: treatment.reservice_unl
        };

        if (treatment.existing) {
          const response = await apiClient.put(`${config.apiUrl}/updXsanering/?token=${token}`, payload);
          if (response.data.status !== 'success') {
            console.error('Failed to update treatment:', treatment.behandling);
          }
        } else {
          const response = await apiClient.post(`${config.apiUrl}/addXSanering/?token=${token}`, payload);
          if (response.data.status !== 'success') {
            console.error('Failed to add treatment:', treatment.behandling);
          }
        }
      }
      navigate(`/agreement-new-prod/${kundeId}/${avtaleId}/${behandlingsadrId}`);
    } catch (error) {
      console.error('An error occurred while adding or updating treatments:', error);
      notify('error', translations['addUpdateTreatmentError'] || 'An error occurred while adding or updating treatments.');
    }
  };

  if (loading) {
    return (
      <MainLayout title={translations['agreementNewTreat'] || "Agreement New Treat"}>
        <div>{translations['loading'] || 'Loading...'}</div>
      </MainLayout>
    );
  }

  return (
    <MainLayout title={translations['agreementNewTreat'] || "Agreement New Treat"}>
      <div className="agreement-new-treat-container">
        <form onSubmit={handleSubmit} className="agreement-new-treat-form">
          <div className="treatment-selection">
            <label htmlFor="treatmentSelect">{translations['selectTreatment'] || 'Select Treatment'}:</label>
            <select id="treatmentSelect" onChange={handleTreatmentSelect}>
              <option value="">{translations['chooseTreatment'] || 'Choose a treatment'}</option>
              {availableTreatments.map(treatment => (
                <option key={treatment.behandling_id} value={treatment.behandling_id}>
                  {treatment.behandling}
                </option>
              ))}
            </select>
          </div>
          <div className="treatment-list">
            {selectedTreatments.map((treatment, index) => (
              <div key={index} className="treatment-item">
                <h3>{treatment.behandling}</h3>
                <label>{translations['extraSanitation'] || 'Extra Sanitation without cost'}:
                  <input
                    type="checkbox"
                    checked={treatment.extra_sanering}
                    onChange={(e) => handleTreatmentChange(index, 'extra_sanering', e.target.checked)}
                  />
                </label>
                <label>{translations['numberOfReServices'] || 'Number of re-services'}:
                  <input
                    type="number"
                    value={treatment.reservice}
                    disabled={treatment.reservice_unl}
                    onChange={(e) => handleTreatmentChange(index, 'reservice', parseInt(e.target.value))}
                  />
                </label>
                <label>{translations['unlimitedReServices'] || 'Unlimited re-services'}:
                  <input
                    type="checkbox"
                    checked={treatment.reservice_unl}
                    onChange={(e) => handleTreatmentChange(index, 'reservice_unl', e.target.checked)}
                  />
                </label>
                <button type="button" className="delete-button" onClick={() => handleRemoveTreatment(index)}>{translations['delete'] || 'Delete'}</button>
              </div>
            ))}
          </div>
          <div className="form-actions">
            <button type="submit">{translations['saveContinue'] || 'Save and Continue'}</button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default AgreementNewTreat;

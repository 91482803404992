import React, { useState, useEffect, useCallback, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import MainLayout from '../components/MainLayout';
import PageHeader from '../components/PageHeader'; // Importer PageHeader-komponenten
import config from '../config';
import { FaFilePdf } from 'react-icons/fa'; // Importer PDF-ikonet fra react-icons
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext
import './Agreements.css'; // Importer CSS-filen

const Agreements = () => {
  const { translations } = useContext(LanguageContext); // Use translations from LanguageContext
  const [agreements, setAgreements] = useState([]);
  const [expandedAgreement, setExpandedAgreement] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');

  const loadAgreements = useCallback(() => {
    apiClient
      .get(`${config.apiUrl}/getAllAgrmntsWithDocs?token=${token}`)
      .then((response) => {
        if (response.data.status === 'success') {
          setAgreements(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching agreements:', error);
      });
  }, [token]);

  useEffect(() => {
    if (token) {
      loadAgreements();
    }
  }, [token, loadAgreements]);

  const toggleAccordion = (agreementId) => {
    setExpandedAgreement(expandedAgreement === agreementId ? null : agreementId);
  };

  const filteredAgreements = agreements.filter((agreement) => {
    const { kunde_navn, agrmt_name, avtaletype, behandling } = agreement;
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      (kunde_navn && kunde_navn.toLowerCase().includes(lowerCaseQuery)) ||
      (agrmt_name && agrmt_name.toLowerCase().includes(lowerCaseQuery)) ||
      (avtaletype && avtaletype.toLowerCase().includes(lowerCaseQuery)) ||
      (behandling && behandling.some(behandlingItem => behandlingItem.toLowerCase().includes(lowerCaseQuery)))
    );
  });

  return (
    <MainLayout title={translations['agreements'] || "Agreements"}> {/* Send title prop */}
      <PageHeader
        title={translations['agreementsHeaderTitle'] || ''}
        description={translations['agreementsHeaderDescription'] || "On this page, you can view agreements made in the last 90 days and download the agreement document."}
      />
      <div className="agreements-container">
        <input
          type="text"
          placeholder={translations['searchPlaceholder'] || "Search by customer name, agreement name, agreement type or treatment"}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-box"
        />
        {filteredAgreements.map((agreement) => (
          <div key={agreement.avtale_id} className="agreement-card">
            <button 
              className="accordion-button"
              onClick={() => toggleAccordion(agreement.avtale_id)}
            >
              {`${agreement.kunde_navn} - ${translations['agreementNo'] || 'Agreement NO'} ${agreement.avtale_id}`}
              <span className="date-span">{new Date(agreement.dato_start).toLocaleDateString('en-GB')}</span>
            </button>
            {expandedAgreement === agreement.avtale_id && (
              <div className="agreement-details">
                <div className="agreement-body">
                  <div className="agreement-body-left">
                    <p><strong>{agreement.kunde_navn}</strong></p>
                    <p>{agreement.behandling.join(', ')}</p>
                    <p>{agreement.avtaletype}</p>
                  </div>
                  <div className="agreement-footer">
                    {agreement.avtaleDokumenter.map((doc) => (
                      <button 
                        key={doc.agreement_doc_id} 
                        className="agreement-footer-button" 
                        onClick={() => window.open(`${config.apiUrl}/getFileAgreementDocument/${doc.agreement_doc_id}?token=${token}`, '_blank')}
                      >
                        <FaFilePdf className="pdf-icon" />
                        {doc.agrmt_doc_conf}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default Agreements;

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for axios
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext
import config from '../config'; // Import config
import './MainMenu.css';

const MainMenu = () => {
  const [userData, setUserData] = useState({});
  const [incompleteReportsCount, setIncompleteReportsCount] = useState(0); // State for incomplete reports count
  const navigate = useNavigate();
  const { translations } = useContext(LanguageContext); // Use translations from LanguageContext

  useEffect(() => {
    const name = localStorage.getItem('name');
    const fname = localStorage.getItem('fname');
    const languageId = localStorage.getItem('language_id');
  
    if (name && fname && languageId) {
      setUserData({
        name: name,
        fname: fname,
        languageId: languageId
      });
    }
  
    // Fetch the count of incomplete reports
    const fetchIncompleteReportsCount = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userid');
  
      if (token && userId) {
        try {
          const response = await apiClient.get(`${config.apiUrl}/countNotCompleteAssmnt/${userId}/`, {
            params: { token: token }
          });
  
          if (response.data.status === 'success') {
            setIncompleteReportsCount(parseInt(response.data.count, 10)); // Convert count to integer
          } else {
            console.error('Failed to fetch incomplete reports count:', response.data.message);
          }
        } catch (error) {
          console.error('Failed to fetch incomplete reports count:', error);
        }
      }
    };
  
    fetchIncompleteReportsCount();
  }, []);

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return translations.goodMorning;
    if (hour < 18) return translations.goodAfternoon;
    return translations.goodEvening;
  };

  useEffect(() => {
    // Disable back navigation
    const handleBackButtonEvent = (e) => {
      e.preventDefault();
      navigate('/mainmenu');
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
    };
  }, [navigate]);

  return (
    <MainLayout>
      {(showMessage) => (
        <div className="main-menu">
          <div className="greeting">
            <h1>{getGreeting()}, {userData.fname}</h1>
            <p>{new Date().toLocaleDateString()}</p>
          </div>
          <div className="menu-grid">
            <div className="menu-item" onClick={() => navigate('/driving-routes')}>
              <img src="/driving-route.png" alt="Driving Routes" />
              <span>{translations.drivingRoutes}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/agreements')}>
              <img src="/signed_agreements.png" alt="Agreements" />
              <span>{translations.agreements}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/customers')}>
              <img src="/customer-search.png" alt="Customers" />
              <span>{translations.customers}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/search-address')}>
              <img src="/address-search.png" alt="Search Address" />
              <span>{translations.searchAddress}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/new-inspection')}>
              <img src="/new-inspection.png" alt="New Inspection" />
              <span>{translations.newInspection}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/my-complete-tasks')}>
              <img src="/my-complete-task.png" alt="My Complete Tasks" />
              <span>{translations.myCompleteTasks}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/incomplete-reports')}>
              <img src="/incomplete-reports.png" alt="Incomplete Reports" />
              {incompleteReportsCount > 0 && (
                <span className="badge">{incompleteReportsCount}</span>
              )}
              <span>{translations.incompleteReports}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/future-jobs')}>
              <img src="/future-job.png" alt="Future Jobs" />
              <span>{translations.futureJobs}</span>
            </div>
            <div className="menu-item" onClick={() => navigate('/new-work-order')}>
              <img src="/new-work-order.png" alt="New Work Order" />
              <span>{translations.newWorkOrder}</span>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default MainMenu;

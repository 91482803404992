import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext

const CheckType = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [allBehandlinger, setAllBehandlinger] = useState([]);
  const [selectedBehandlinger, setSelectedBehandlinger] = useState([]);
  const [initialBehandlinger, setInitialBehandlinger] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Legg til isLoading state
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk translations fra LanguageContext

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allBehandlingerResponse = await apiClient.get(`${config.apiUrl}/getCheckType/?token=${token}`);
        console.log('All Behandlinger Response:', allBehandlingerResponse);
        if (allBehandlingerResponse.data.status === 'success') {
          setAllBehandlinger(allBehandlingerResponse.data.data);
        }

        const checkTypeResponse = await apiClient.get(`${config.apiUrl}/getCheckTypeOppdrag/${oppdrag_id}/?token=${token}`);
        console.log('Check Type Response:', checkTypeResponse);
        if (checkTypeResponse.data.status === 'success' && checkTypeResponse.data.data.length > 0) {
          const initialSelections = checkTypeResponse.data.data.map(item => item.behandling_id.toString());
          setSelectedBehandlinger(initialSelections);
          setInitialBehandlinger(initialSelections);
        } else {
          const behandlingerResponse = await apiClient.get(`${config.apiUrl}/getBehandlingerByBehandlingsadrId/${behandlingsadrId}/?token=${token}`);
          console.log('Behandlinger By BehandlingsadrId Response:', behandlingerResponse);
          if (behandlingerResponse.data.status === 'success') {
            const initialSelections = behandlingerResponse.data.data.map(item => item.behandling_id.toString());
            setSelectedBehandlinger(initialSelections);
            setInitialBehandlinger(initialSelections);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        notify('error', translations.failedToFetchData || 'Failed to fetch data. Please try again.');
      } finally {
        setIsLoading(false); // Sett isLoading til false når dataene er lastet inn
      }
    };

    fetchData();
  }, [behandlingsadrId, oppdrag_id, token, notify, translations]);

  const handleBehandlingChange = (e) => {
    const value = e.target.value;
    setSelectedBehandlinger(prevState =>
      prevState.includes(value) ? prevState.filter(id => id !== value) : [...prevState, value]
    );
  };

  const handleBack = () => {
    navigate(`/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`);
  };

  const handleSave = async () => {
    if (selectedBehandlinger.length === 0) {
      notify('error', translations.selectAtLeastOneCategory || 'Please select at least one category.');
      return;
    }
  
    try {
      // Slett behandlinger som har blitt fjernet
      const deletePromises = initialBehandlinger
        .filter(behandling_id => !selectedBehandlinger.includes(behandling_id))
        .map(behandling_id => 
          apiClient.delete(`${config.apiUrl}/delCheckTypeNew/?token=${token}`, { data: { behandling_id, oppdrag_id } })
        );
  
      // Legg til behandlinger som har blitt lagt til
      const addPromises = selectedBehandlinger
        .filter(behandling_id => !initialBehandlinger.includes(behandling_id))
        .map(behandling_id => 
          apiClient.post(`${config.apiUrl}/addCheckType/?token=${token}`, { behandling_id, oppdrag_id })
        );
  
      await Promise.all([...deletePromises, ...addPromises]);
  
      // Bruk hooken for å oppdatere oppdrag progress
      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`, navigate);
  
      if (success) {
        notify('success', translations.treatmentsSaved || 'Treatments saved successfully.');
        navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error saving treatments:', error);
      notify('error', translations.failedToSaveTreatments || 'Failed to save treatments. Please try again.');
    }
  };
  

  return (
    <MainLayout title={translations.checkType || 'Check Type'}>
      <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <>
            <div className="cr-box">
              <h2 className="cr-heading">{translations.controlledOrTreated || 'The following has been controlled/treated:'}</h2>
              <div className="cr-radio-group">
                {allBehandlinger.map(behandling => (
                  <label key={behandling.behandling_id} className="cr-radio-label">
                    <span>{behandling.behandling}</span>
                    <input
                      type="checkbox"
                      name="behandling"
                      value={behandling.behandling_id.toString()}
                      checked={selectedBehandlinger.includes(behandling.behandling_id.toString())}
                      onChange={handleBehandlingChange}
                      className="cr-radio-input"
                    />
                    <span className="cr-radio-custom"></span>
                  </label>
                ))}
              </div>
            </div>
            <div className="cr-buttons">
              <button className="cr-button cr-back-button" onClick={handleBack}>{translations.back || 'Back'}</button>
              <button className="cr-button cr-save-button" onClick={handleSave}>{translations.save || 'Save'}</button>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default CheckType;

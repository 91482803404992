import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css'; // Import global styles
import './global.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ToastProvider } from './contexts/ToastContext'; 
import { FaExternalLinkAlt } from 'react-icons/fa';  // Import correct Font Awesome icon

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ToastProvider>
      <Router>
        <App />
        <AddToHomeScreenPrompt />
      </Router>
    </ToastProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
reportWebVitals();

function AddToHomeScreenPrompt() {
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIos(/iphone|ipad|ipod/.test(userAgent));
    setIsInStandaloneMode(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  if (!isIos || isInStandaloneMode) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      width: '100%',
      background: '#f8f9fa',
      padding: '10px 0', // Kun topp og bunn padding her
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      zIndex: 9999,
      textAlign: 'center',
    }}>
      <div style={{ 
        margin: '0 auto', 
        paddingLeft: '20px', 
        paddingRight: '30px', 
        maxWidth: '90%' // Eller sett en konkret bredde som passer
      }}>
        <p style={{ margin: 0 }}>
          To add this app to your home screen, tap{' '}
          <FaExternalLinkAlt style={{ verticalAlign: 'middle', color: '#007AFF', width: '20px', height: '20px' }} />
          {' '}and select "Add to Home Screen".
        </p>
      </div>
    </div>
  );
}

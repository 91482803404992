import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext

const Filter = ({ priorityFilter, treatmentFilter, treatmentOptions, setPriorityFilter, setTreatmentFilter, handleFilterChange, disabled }) => {
  const { translations } = useContext(LanguageContext); // Use translations from LanguageContext

  const handlePriorityChange = (e) => {
    if (disabled) return;
    const { value, checked } = e.target;
    const newPriorityFilter = checked 
      ? [...priorityFilter, parseInt(value)] 
      : priorityFilter.filter(item => item !== parseInt(value));
    setPriorityFilter(newPriorityFilter);
    handleFilterChange(newPriorityFilter, treatmentFilter);
  };

  const handleTreatmentChange = (e) => {
    if (disabled) return;
    const { value, checked } = e.target;
    const newTreatmentFilter = checked 
      ? [...treatmentFilter, parseInt(value)] 
      : treatmentFilter.filter(item => item !== parseInt(value));
    setTreatmentFilter(newTreatmentFilter);
    handleFilterChange(priorityFilter, newTreatmentFilter);
  };

  return (
    <div className={`filter-container ${disabled ? 'disabled' : ''}`}>
      <div className="filter-section">
        <h4>{translations['priority'] || 'Priority'}</h4> {/* Use translation for Priority */}
        <label>
          <input 
            type="checkbox" 
            value="1" 
            onChange={handlePriorityChange} 
            checked={priorityFilter.includes(1)} 
            disabled={disabled} 
          />
          {translations['orange'] || 'Orange'} {/* Use translation for Orange */}
        </label>
        <label>
          <input 
            type="checkbox" 
            value="2" 
            onChange={handlePriorityChange} 
            checked={priorityFilter.includes(2)} 
            disabled={disabled} 
          />
          {translations['yellow'] || 'Yellow'} {/* Use translation for Yellow */}
        </label>
        <label>
          <input 
            type="checkbox" 
            value="3" 
            onChange={handlePriorityChange} 
            checked={priorityFilter.includes(3)} 
            disabled={disabled} 
          />
          {translations['green'] || 'Green'} {/* Use translation for Green */}
        </label>
      </div>
      <div className="filter-section">
        <h4>{translations['treatment'] || 'Treatment'}</h4> {/* Use translation for Treatment */}
        {treatmentOptions.map(option => (
          <label key={option.behandling_id}>
            <input 
              type="checkbox" 
              value={option.behandling_id} 
              onChange={handleTreatmentChange} 
              checked={treatmentFilter.includes(option.behandling_id)} 
              disabled={disabled} 
            />
            {translations[option.behandling] || option.behandling} {/* Use translation for treatment name */}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Filter;

import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config'; // Importer config
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const Products = () => {
  const { behandlingsadrId, oppdrag_id } = useParams();
  const navigate = useNavigate();
  
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [antall, setAntall] = useState('');
  const [mengdekommentar, setMengdekommentar] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Legg til isLoading state
  const token = localStorage.getItem('token');  // Hent token fra localStorage
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsResponse = await apiClient.get(`${config.apiUrl}/getProducts/?token=${token}`);
        if (productsResponse.data.status === 'success') {
          const availableProducts = productsResponse.data.data;
          const selectedProductsResponse = await apiClient.get(`${config.apiUrl}/getProductsSelected/${oppdrag_id}/?token=${token}`);
          if (selectedProductsResponse.data.status === 'success') {
            setSelectedProducts(selectedProductsResponse.data.data);
            const selectedProductIds = selectedProductsResponse.data.data.map(p => p.produkt_id);
            const filteredProducts = availableProducts.filter(p => !selectedProductIds.includes(p.produkt_id));
            setProducts(filteredProducts);
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        notify('error', translations.fetchProductsError || 'Failed to fetch products. Please try again.');
      } finally {
        setIsLoading(false); // Sett isLoading til false når dataene er lastet inn
      }
    };

    fetchProducts();
  }, [oppdrag_id, token, notify, translations]);

  const handleProductChange = (e) => {
    const productId = e.target.value;
    const product = products.find(p => p.produkt_id === parseInt(productId));
    setSelectedProductId(productId);
    setProductDetails(product);
    setAntall('');
    setMengdekommentar('');
    // Legg produktet til i valgte produkter
    setSelectedProducts([...selectedProducts, { ...product, isNew: true, antall: '', mengdekommentar: '' }]);
    // Fjern produktet fra dropdown-listen
    setProducts(products.filter(p => p.produkt_id !== parseInt(productId)));
    setSelectedProductId('');
  };

  const handleUpdateProduct = (productId, newAntall, newMengdekommentar) => {
    const updatedProducts = selectedProducts.map(p => {
      if (p.produkt_id === productId) {
        return { ...p, antall: newAntall, mengdekommentar: newMengdekommentar };
      }
      return p;
    });
    setSelectedProducts(updatedProducts);
  };

  const handleDeleteProduct = (productId) => {
    const payload = {
      oppdrag_id,
      produkt_id: productId
    };

    apiClient.delete(`${config.apiUrl}/delProductsSelected/?token=${token}`, { data: payload })
      .then(response => {
        if (response.data.status === 'success') {
          const deletedProduct = selectedProducts.find(p => p.produkt_id === productId);
          setSelectedProducts(selectedProducts.filter(p => p.produkt_id !== productId));
          setProducts([...products, deletedProduct]);
          notify('success', translations.productDeleted || 'Product deleted successfully.');
        }
      })
      .catch(error => {
        console.error('Error deleting product:', error);
        notify('error', translations.deleteProductError || 'Failed to delete product. Please try again.');
      });
  };

  const handleSave = async () => {
    const addProducts = selectedProducts.filter(p => p.isNew);
    const updateProducts = selectedProducts.filter(p => !p.isNew);
  
    try {
      // Legg til nye produkter
      const addProductPromises = addProducts.map(product => {
        const payload = {
          antall: product.antall,
          mengdekommentar: product.mengdekommentar,
          oppdrag_id,
          produkt_id: product.produkt_id
        };
  
        return apiClient.post(`${config.apiUrl}/addProductsSelected/?token=${token}`, payload)
          .then(response => {
            if (response.data.status === 'success') {
              product.isNew = false; // Merk produktet som lagt til
            }
          })
          .catch(error => console.error('Error adding product:', error));
      });
  
      // Oppdater eksisterende produkter
      const updateProductPromises = updateProducts.map(product => {
        const payload = {
          antall: product.antall,
          mengdekommentar: product.mengdekommentar,
          oppdrag_id,
          produkt_id: product.produkt_id
        };
  
        return apiClient.post(`${config.apiUrl}/updProductsSelected/?token=${token}`, payload)
          .catch(error => console.error('Error updating product:', error));
      });
  
      await Promise.all([...addProductPromises, ...updateProductPromises]);
  
      // Bruk hooken for å oppdatere oppdrag progress
      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/comment/${behandlingsadrId}/${oppdrag_id}`, navigate);
  
      if (success) {
        notify('success', translations.productsSaved || 'Products saved successfully.');
        navigate(`/legacy/comment/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error saving products:', error);
      notify('error', translations.saveProductsError || 'Failed to save products. Please try again.');
    }
  };
  
  return (
    <MainLayout title={translations.productsTitle || "Products"}>
    <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
      {isLoading ? (
        <div>{translations.loading || 'Loading...'}</div>
      ) : (
        <div className="cr-box">
          <h2 className="cr-heading">{translations.selectProducts || 'Select Applied Products'}</h2>
          <div className="cr-select-container">
            <select
              className="cr-select"
              value={selectedProductId}
              onChange={handleProductChange}
            >
              <option value="">{translations.selectProductPlaceholder || 'Select a product'}</option>
              {products.map(product => (
                <option key={product.produkt_id} value={product.produkt_id}>
                  {product.produkt}
                </option>
              ))}
            </select>
          </div>

          {selectedProducts.map(product => (
            <div key={product.produkt_id} className="cr-product-details">
              <p><strong>{translations.productLabel || 'Product'}:</strong> {product.produkt}</p>
              <p><strong>{translations.activeSubstanceLabel || 'Active Substance'}:</strong> {product.aktivtstoff}</p>
              <p><strong>{translations.concentrationLabel || 'Concentration'}:</strong> {product.konsentrasjon}</p>
              <p><strong>{translations.unitLabel || 'Unit'}:</strong> {product.maleenhet}</p>
              <div className="cr-input-group">
                <label>{translations.amountLabel || 'Amount'}</label>
                <input
                  type="number"
                  value={product.antall}
                  className="cr-amount-input" /* Legg til denne klassen */
                  onChange={(e) => handleUpdateProduct(product.produkt_id, e.target.value, product.mengdekommentar)}
                />
              </div>
              <div className="cr-input-group">
                <label>{translations.commentLabel || 'Comment'}</label>
                <textarea
                  value={product.mengdekommentar}
                  onChange={(e) => handleUpdateProduct(product.produkt_id, product.antall, e.target.value)}
                ></textarea>
              </div>
              <button className="cr-delete-button" onClick={() => handleDeleteProduct(product.produkt_id)}>{translations.delete || 'Delete'}</button>
            </div>
          ))}
        </div>
      )}
      <div className="cr-buttons">
        <button className="cr-button cr-back-button" onClick={() => navigate(`/legacy/risk/${behandlingsadrId}/${oppdrag_id}`)}>{translations.backButton || 'Back'}</button>
        <button className="cr-button cr-save-button" onClick={handleSave}>{translations.saveButton || 'Save'}</button>
      </div>
    </div>
    </MainLayout>
  );
};

export default Products;

import React, { useState, useEffect, useCallback, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for axios
import MainLayout from '../components/MainLayout';
import PageHeader from '../components/PageHeader';
import config from '../config';
import { FaFilePdf } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext';
import './MyCompleteTasks.css';

const MyCompleteTasks = () => {
  const { translations } = useContext(LanguageContext);
  const [tasks, setTasks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear().toString());
  const userId = localStorage.getItem('userid');
  const token = localStorage.getItem('token');

  const loadTasks = useCallback(() => {
    apiClient
      .get(`${config.apiUrl}/getMyCompleteAssignment/${userId}?token=${token}`)
      .then((response) => {
        if (response.data.status === 'success') {
          setTasks(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
      });
  }, [userId, token]);

  useEffect(() => {
    if (userId && token) {
      loadTasks();
    }
  }, [userId, token, loadTasks]);

  const handleDownload = (taskId) => {
    const url = `${config.apiUrl}/getFileReport/${taskId}?token=${token}`;
    window.open(url, '_blank');
  };

  const filteredTasks = tasks
    .filter(task => {
      const { navn, behandlingsadr, oppdrag_id } = task;
      const lowerCaseQuery = searchQuery.toLowerCase();
      return (
        (navn && navn.toLowerCase().includes(lowerCaseQuery)) ||
        (behandlingsadr && behandlingsadr.toLowerCase().includes(lowerCaseQuery)) ||
        (oppdrag_id && oppdrag_id.toString().includes(lowerCaseQuery))
      ) &&
      new Date(task.dato_start).getFullYear().toString() === yearFilter;
    })
    .sort((a, b) => new Date(b.dato_start) - new Date(a.dato_start));

  const handleYearChange = (e) => {
    setYearFilter(e.target.value);
  };

  return (
    <MainLayout title={translations.myCompletedTasks || 'My Completed Tasks'}>
      <PageHeader
        title=""
        description={translations.myCompletedTasksDescription || 'View and manage your completed tasks.'}
      />
      <div className="filter-container">
        <input
          type="text"
          placeholder={translations.searchPlaceholder || 'Search by name, address or task ID'}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-box"
        />
        <div className="year-filter-container">
          <label className="year-filter-label">{translations.selectYear || 'Select Year'}</label>
          <select value={yearFilter} onChange={handleYearChange} className="filter-dropdown">
            {[...new Set(tasks.map(task => new Date(task.dato_start).getFullYear()))]
              .sort((a, b) => b - a)
              .map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
          </select>
        </div>
      </div>
      <div className="tasks-container">
        {filteredTasks.length > 0 ? (
          filteredTasks.map((task) => (
            <div key={task.oppdrag_id} className="task-card">
              <div className="task-header">
                <h2>{`${task.navn || translations.customerName || 'Customer'} - ${translations.task || 'Task'}: ${task.oppdrag_id}`}</h2>
                <span>{new Date(task.dato_start).toLocaleDateString()}</span>
              </div>
              <div className="task-body">
                <div className="task-body-left">
                  <p><strong>{task.avtaletype || translations.agreementType || 'Agreement Type'}</strong></p>
                  <p>{task.behandlingsadr || translations.address || 'Address'}</p>
                  <p>{task.type_besok || translations.visitType || 'Visit Type'}</p>
                  {task.utfort && <p>{task.utfort}</p>}
                  <p>{translations.reportSent || 'Report sent'}: {task.rapport_sendt ? translations.yes || 'Yes' : translations.no || 'No'}</p>
                </div>
                <div className="task-footer">
                  <button className="task-footer-button" onClick={() => handleDownload(task.oppdrag_id)}>
                    <FaFilePdf className="pdf-icon" />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>{translations.noCompletedTasksFound || 'No completed tasks found.'}</p>
        )}
      </div>
    </MainLayout>
  );
};

export default MyCompleteTasks;

import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

const languages = {
  en: require('../locales/en.json'),
  no: require('../locales/no.json'),
  da: require('../locales/da.json'),
  de: require('../locales/de.json'), // Add German translations
  pl: require('../locales/pl.json'), // Add Polish translations
  sv: require('../locales/sv.json'), // Add Swedish translations
  // Add other languages as needed
};

// Mapping from language_id to language code
const languageIdToCode = {
  1: 'en',
  2: 'no',
  3: 'da',
  4: 'sv',
  5: 'de',
  6: 'pl'
  // Add other mappings as needed
};

const LanguageProvider = ({ children }) => {
  const storedLanguageId = localStorage.getItem('language_id') || '1';
  const storedLanguage = languageIdToCode[storedLanguageId] || 'en';
  const [language, setLanguage] = useState(storedLanguage);
  const [translations, setTranslations] = useState(languages[storedLanguage] || languages['en']);

  useEffect(() => {
    const validLanguage = languages[language] ? language : 'en';
    setTranslations(languages[validLanguage]);
  }, [language]);

  const changeLanguage = (langId) => {
    const langCode = languageIdToCode[langId] || 'en';
    setLanguage(langCode);
    localStorage.setItem('language_id', langId);
  };

  return (
    <LanguageContext.Provider value={{ language, translations, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };

// Customers.js
import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import MainLayout from '../components/MainLayout';
import PageHeader from '../components/PageHeader';
import config from '../config';
import { FaSearch } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';

const Customers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState([]);
  const token = localStorage.getItem('token');
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();

  // Hente søkeuttrykket fra Session Storage når komponenten laster
  useEffect(() => {
    const savedSearchTerm = sessionStorage.getItem('searchTermCust');
    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      apiClient
        .post(`${config.apiUrl}/searchCust?token=${token}`, { str: searchTerm })
        .then((response) => {
          if (response.data.status === 'success') {
            setCustomers(response.data.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching customers:', error);
        });
    } else {
      setCustomers([]);
    }
  }, [searchTerm, token]);

  const handleNewCustomer = () => {
    navigate('/customernew');
  };

  // Lagre søkeuttrykket i Session Storage under nøkkelen searchTermCust
  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    sessionStorage.setItem('searchTermCust', value);
  };

  return (
    <MainLayout title={translations['customerSearch'] || 'Customer Search'}>
      <PageHeader description={translations['customerSearchDescription'] || 'Search for a customer by name or details'} />
      <div className="search-container">
        <input
          type="text"
          placeholder={translations['searchCustomerPlaceholder'] || 'Search customer...'}
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="search-input"
        />
        <div className="search-button">
          <FaSearch />
        </div>
      </div>
      <button className="new-customer-button" onClick={handleNewCustomer}>
        {translations['newCustomer'] || 'New Customer'}
      </button>
      <div className="customers-list">
        {customers.map((customer) => (
          <div key={customer.kunde_id} className="customer-card">
            <h3>{customer.navn}</h3>
            <p>{customer.adresse}</p>
            <p>{customer.postnr} {customer.poststed}</p>
            <h2>{translations['customerNo'] || 'Customer No'}: {customer.kundenr}</h2>
            <p>{translations['serviceAgreements'] || 'Service Agreements'}: {customer.cnt_serviceagrmt}</p>
            <p>{translations['workAgreements'] || 'Work Agreements'}: {customer.cnt_workagrmt}</p>
            <button
              className="customer-overview-button"
              onClick={() => navigate(`/customeroverview/${customer.kunde_id}`)}
            >
              {translations['customerOverview'] || 'Customer Overview'}
            </button>
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default Customers;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Preview.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../../config';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'react-modal';
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken

const Preview = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [reportData, setReportData] = useState({});
  const [treatments, setTreatments] = useState([]);
  const [products, setProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const customerSignatureRef = useRef(null);
  const technicianSignatureRef = useRef(null);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken

  useEffect(() => {
    apiClient.get(`${config.apiUrl}/getReportPreview/${oppdrag_id}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setReportData(response.data.data.oppdrag_details[0]);
          setTreatments(response.data.data.treatments);
          setProducts(response.data.data.products);
        }
      })
      .catch(error => {
        console.error('Error fetching report preview:', error);
      });
  }, [oppdrag_id, token]);

  const handleBack = () => {
    navigate(`/legacy/photos/${behandlingsadrId}/${oppdrag_id}`);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleResetSignature = (signatureRef) => {
    signatureRef.current.clear();
  };

  const handleSaveAndSubmit = async () => {
    if (technicianSignatureRef.current.isEmpty()) {
      notify('error', 'Technician signature is required.');
      return;
    }

    const customerSignature = customerSignatureRef.current.isEmpty() ? '' : customerSignatureRef.current.toDataURL();
    const technicianSignature = technicianSignatureRef.current.toDataURL();

    setIsSaving(true);

    try {
      await apiClient.post(`${config.apiUrl}/updSignatures`, {
        oppdrag_id,
        signatur_kunde: customerSignature,
        signatur_teknikker: technicianSignature,
        token
      });

      await apiClient.post(`${config.apiUrl}/completeReport`, {
        dato_ferdig: new Date().toISOString().split('T')[0],
        status_id: 2,
        oppdrag_id,
        token
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, null, navigate);

      if (success) {
        notify('success', 'Report completed successfully.');
        navigate(`/legacy/effortssummary/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error completing report:', error);
      notify('error', 'Failed to complete report. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <MainLayout title="Preview">
      <div className="preview-cr-container">
        <div className="preview-cr-box">
          <div id="pageContent">
            <div className="preview-main_wrapper">
              <div className="preview-trans-head">
                <h1 className="text-center preview-green-text">Servicerapport</h1>
              </div>
              <div className="preview-rw">
                <div className="preview-cl preview-cl50">
                  <table className="preview-cntable">
                    <tbody>
                      <tr>
                        <th className="preview-cl40">Kunde nr.:</th>
                        <td>{reportData.kundenr}</td>
                      </tr>
                      <tr>
                        <th>Kunde:</th>
                        <td>{reportData.navn}</td>
                      </tr>
                      <tr>
                        <th>Adresse:</th>
                        <td>{reportData.adresse}<br />{reportData.postnr} {reportData.poststed}</td>
                      </tr>
                      <tr>
                        <th>Telefon:</th>
                        <td>{reportData.telefon}</td>
                      </tr>
                      <tr>
                        <th>E-post:</th>
                        <td>{reportData.epost}</td>
                      </tr>
                      <tr>
                        <th>Kontakt:</th>
                        <td>{reportData.kontakt}</td>
                      </tr>
                      <tr>
                        <th>Behandlingsadr.:</th>
                        <td>{reportData.behandlingsadr}<br />{reportData.postnr_beh} {reportData.poststed_beh}</td>
                      </tr>
                      <tr>
                        <th>Type besøk:</th>
                        <td>{reportData.typebesok}</td>
                      </tr>
                      <tr>
                        <th className="preview-cl30">Kontroll/behandling:</th>
                        <td>
                          {treatments.map(treatment => (
                            <span key={treatment.behandling_id}>{treatment.behandling}, </span>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="preview-cl preview-cl50">
                  <img className="preview-full-image" src={reportData.oppdrag_bilde} alt="Behandlingsadresse" />
                </div>
              </div>
            </div>
            <div className="preview-transparent-stripe">&nbsp;</div>
            <div className="preview-main_wrapper">
              <table className="preview-cntable">
                <tbody>
                  <tr>
                    <th className="preview-cl30">Hva er utført?</th>
                    <td className="preview-cl70">{reportData.utfort}</td>
                  </tr>
                  <tr>
                    <th className="preview-cl30">Har det vært skadedyraktivitet?</th>
                    <td className="preview-cl70">{reportData.s_aktivitet ? 'Ja' : 'Nei'}</td>
                  </tr>
                  <tr>
                    <th className="preview-cl30">Valg av metode:</th>
                    <td className="preview-cl70">{reportData.metode_bekjempelse}</td>
                  </tr>
                  <tr>
                    <th className="preview-cl30">Metode begrunnelse:</th>
                    <td className="preview-cl70">{reportData.metode_begrunnelse}</td>
                  </tr>
                  <tr>
                    <th className="preview-cl30">Er nabovarsel gitt?</th>
                    <td className="preview-cl70">{reportData.nabovarsel ? 'Ja' : 'Nei'}</td>
                  </tr>
                  <tr>
                    <th className="preview-cl30">Kommentar til nabovarsel:</th>
                    <td className="preview-cl70">{reportData.nabovarsel_kommentar}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="preview-main_wrapper">
              <table className="preview-cntable">
                <thead>
                  <tr>
                    <th>Anvendt produkt</th>
                    <th>Aktivt stoff</th>
                    <th>Konsentrasjon</th>
                    <th>Måleenhet</th>
                    <th>Ant.</th>
                    <th>Kommentar</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map(product => (
                    <tr key={product.produkt_id}>
                      <td>{product.produkt}</td>
                      <td>{product.aktivtstoff}</td>
                      <td>{product.konsentrasjon}</td>
                      <td>{product.maleenhet}</td>
                      <td>{product.antall}</td>
                      <td>{product.mengdekommentar}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="preview-main_wrapper">
              <table className="preview-cntable">
                <tbody>
                  <tr>
                    <th>Resultat av behandling</th>
                  </tr>
                  <tr>
                    <td>{reportData.behandling_resultat}</td>
                  </tr>
                  <tr>
                    <th>Risikovurdering av tiltak</th>
                  </tr>
                  <tr>
                    <td>{reportData.risikovurdering_tiltak}</td>
                  </tr>
                  <tr>
                    <th>Kommentar til kontroll/behandling</th>
                  </tr>
                  <tr>
                    <td>{reportData.behandling_kommentar}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="preview-main_wrapper">
              <div className="preview-rw">
                <div className="preview-cl">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-cr-buttons">
          <button className="preview-cr-button preview-cr-back-button" onClick={handleBack}>Back</button>
          <button className="preview-cr-button preview-cr-save-button" onClick={handleOpenModal}>Sign</button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Signature Modal"
        className="preview-cr-modal"
        overlayClassName="preview-cr-overlay"
      >
        <div className="preview-cr-modal-content">
          <h2 className="preview-cr-modal-title">Sign Report</h2>
          <div className="preview-cr-signature-group">
            <h3>Customer Signature</h3>
            <SignatureCanvas
              ref={customerSignatureRef}
              penColor="black"
              canvasProps={{ className: 'preview-cr-signature-canvas' }}
            />
            <button className="preview-cr-reset-button" onClick={() => handleResetSignature(customerSignatureRef)}>Reset</button>
          </div>
          <div className="preview-cr-signature-group">
            <h3>Technician Signature</h3>
            <SignatureCanvas
              ref={technicianSignatureRef}
              penColor="black"
              canvasProps={{ className: 'preview-cr-signature-canvas' }}
            />
            <button className="preview-cr-reset-button" onClick={() => handleResetSignature(technicianSignatureRef)}>Reset</button>
          </div>
          <div className="preview-cr-modal-buttons">
            <button className="preview-cr-button" onClick={handleCloseModal}>Cancel</button>
            <button className="preview-cr-button" onClick={handleSaveAndSubmit} disabled={isSaving}>Save and Submit</button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
};

export default Preview;

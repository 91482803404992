import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import MainLayout from '../components/MainLayout';
import PageHeader from '../components/PageHeader';
import config from '../config';
import { FaSearch } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';

const SearchAddress = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [addresses, setAddresses] = useState([]);
  const token = localStorage.getItem('token');
  const { translations } = useContext(LanguageContext); // Bruker oversettelser fra LanguageContext
  const navigate = useNavigate();

  // Hente søkeuttrykket fra Session Storage når komponenten laster
  useEffect(() => {
    const savedSearchTerm = sessionStorage.getItem('SearchTermAdr');
    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      apiClient
        .post(`${config.apiUrl}/search?token=${token}`, { str: searchTerm })
        .then((response) => {
          if (response.data.status === 'success') {
            setAddresses(response.data.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching addresses:', error);
        });
    } else {
      setAddresses([]);
    }
  }, [searchTerm, token]);

  // Lagre søkeuttrykket i Session Storage under nøkkelen SearchTermAdr
  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    sessionStorage.setItem('SearchTermAdr', value);
  };

  return (
    <MainLayout title={translations.serviceAddressSearch || 'Service Address Search'}>
      <PageHeader
        title=""
        description={translations.serviceAddressSearchDescription || 'Search for service addresses below'}
      />
      <div className="search-container">
        <input
          type="text"
          placeholder={translations.searchPlaceholder || 'Search...'}
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="search-input"
        />
        <div className="search-button">
          <FaSearch />
        </div>
      </div>
      <div className="addresses-list">
        {addresses.map((address) => (
          <div key={address.behandlingsadr_id} className="address-card">
            <h2>{address.navn}</h2>
            <p>{address.behandlingsadr}</p>
            <p>{address.postnr} {address.poststed}</p>
            <p>{translations.customerNo || 'Customer No'}: {address.kundenr}</p>
            <p>{translations.area || 'Area'}: {address.omrade}</p>
            <button
              className="customer-overview-button"
              onClick={() => navigate(`/serviceaddress/${address.behandlingsadr_id}`)} // Oppdatert navigasjon
            >
              {translations.showServiceAddress || 'Show Service Address'}
            </button>
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default SearchAddress;

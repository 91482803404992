import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaListAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress';
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const WhatWeDone = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [utfort, setUtfort] = useState('');
  const [customTexts, setCustomTexts] = useState([]);
  const [initialUtfort, setInitialUtfort] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getDoneOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.length > 0) {
          setUtfort(response.data.data[0].utfort || '');
          setInitialUtfort(response.data.data[0].utfort || '');
        }
      } catch (error) {
        console.error('Error fetching done oppdrag:', error);
        notify('error', translations.fetchDoneOppdragError || 'Failed to fetch done oppdrag. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCustomTexts = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCustomtext/1/?token=${token}`);
        if (response.data && response.data.data) {
          setCustomTexts(response.data.data.map(item => item.text));
        }
      } catch (error) {
        console.error('Error fetching custom texts:', error);
        notify('error', translations.fetchCustomTextsError || 'Failed to fetch custom texts. Please try again.');
      }
    };

    fetchData();
    fetchCustomTexts();
  }, [oppdrag_id, token, notify, translations]);

  const handleBack = async () => {
    if (utfort.trim() !== initialUtfort.trim()) {
      const confirmSave = window.confirm(translations.unsavedChangesMessage || 'You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving information:', error);
          notify('error', translations.saveInformationError || 'Failed to save information. Please try again.');
        }
      } else {
        navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/pestactivity/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    if (utfort.trim() === '') {
      notify('error', translations.describeWhatDoneError || 'Please describe what has been done.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/updWhatWeDone/?token=${token}`, {
        oppdrag_id,
        utfort: utfort.trim(),
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/method/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', translations.informationSaved || 'Information saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/method/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error saving information:', error);
      notify('error', translations.saveInformationError || 'Failed to save information. Please try again.');
    }
  };

  const handleCustomTextClick = () => {
    setIsModalOpen(true);
  };

  const handleTextSelect = (text) => {
    setUtfort((prev) => `${prev}\n${text}`);
    setIsModalOpen(false);
  };

  const getTextAreaClass = (value) => {
    return value.trim() === '' ? 'ml-mandatory' : 'ml-filled';
  };

  return (
    <MainLayout title={translations.whatWeDoneTitle || 'What We Done'}>
      <div className="cr-container">
        <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <div className="cr-box">
            <h2 className="cr-heading">{translations.whatHasBeenDoneHeading || 'What has been done:'}</h2>
            <div className="cr-textarea-container">
              <textarea
                className={`cr-textarea ${getTextAreaClass(utfort)}`}
                placeholder={translations.describeWhatHasBeenDonePlaceholder || 'Describe what has been done'}
                value={utfort}
                onChange={(e) => setUtfort(e.target.value)}
              />
              <FaListAlt className="cr-customtext-icon" onClick={handleCustomTextClick} />
            </div>
          </div>
        )}
        <div className="cr-buttons">
          <button className="cr-button cr-back-button" onClick={handleBack}>{translations.backButton || 'Back'}</button>
          <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>{translations.saveButton || 'Save'}</button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={translations.selectCustomTextLabel || 'Select Custom Text'}
        className="cr-modal"
        overlayClassName="cr-overlay"
      >
        <h2>{translations.selectCustomTextTitle || 'Select a text'}</h2>
        <ul className="cr-modal-list">
          {customTexts.map((text, index) => (
            <li key={index} onClick={() => handleTextSelect(text)} className="cr-modal-item">
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsModalOpen(false)} className="cr-button cr-cancel-button">{translations.closeButton || 'Close'}</button>
      </Modal>
    </MainLayout>
  );
};

export default WhatWeDone;

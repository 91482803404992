import React, { useState, useEffect, useContext  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaListAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser


const Comment = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [kommentar, setKommentar] = useState('');
  const [customTexts, setCustomTexts] = useState([]);
  const [initialKommentar, setInitialKommentar] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTexts, setModalTexts] = useState([]);
  const [setTextFunction, setSetTextFunction] = useState(() => () => {});
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Legg til oversettelser

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getTreatComment/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.length > 0) {
          setKommentar(response.data.data[0].behandling_kommentar || '');
          setInitialKommentar(response.data.data[0].behandling_kommentar || '');
        }
      } catch (error) {
        console.error('Error fetching treatment comment:', error);
        notify('error', translations.fetchCommentError || 'Failed to fetch treatment comment. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCustomTexts = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCustomtext/7/?token=${token}`);
        if (response.data && response.data.data) {
          setCustomTexts(response.data.data.map(item => item.text));
        }
      } catch (error) {
        console.error('Error fetching custom texts:', error);
        notify('error', translations.fetchCustomTextsError || 'Failed to fetch custom texts. Please try again.');
      }
    };

    fetchData();
    fetchCustomTexts();
  }, [oppdrag_id, token, notify, translations]);

  const handleBack = async () => {
    if (kommentar !== initialKommentar) {
      const confirmSave = window.confirm(translations.unsavedChangesConfirm || 'You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/products/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving comment:', error);
          notify('error', translations.saveCommentError || 'Failed to save comment. Please try again.');
        }
      } else {
        navigate(`/legacy/products/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/products/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    if (kommentar.trim() === '') {
      notify('error', translations.enterComment || 'Please enter a comment.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/updTreatComment/?token=${token}`, {
        oppdrag_id,
        behandling_kommentar: kommentar,
      });

      // Bruk hooken for å oppdatere oppdrag progress
      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/photos/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', translations.commentSaved || 'Comment saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/photos/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error saving comment:', error);
      notify('error', translations.saveCommentError || 'Failed to save comment. Please try again.');
    }
  };

  const handleCustomTextClick = (texts, setTextFunc) => {
    setModalTexts(texts);
    setSetTextFunction(() => setTextFunc);
    setIsModalOpen(true);
  };

  const handleTextSelect = (text) => {
    setTextFunction((prev) => `${prev}\n${text}`);
    setIsModalOpen(false);
  };

  const getTextAreaClass = (value) => {
    if (value) {
      return value.trim() === '' ? 'ml-mandatory' : 'ml-filled';
    }
    return 'ml-mandatory';
  };

  return (
    <MainLayout title={translations.commentTitle || "Comment"}>
      <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <>
            <div className="cr-box">
              <h2 className="cr-heading">{translations.addComment || 'Add any comments to the control/treatment:'}</h2>
              <div className="cr-textarea-container">
                <textarea
                  className={`cr-textarea ${getTextAreaClass(kommentar)}`}
                  placeholder={translations.enterCommentPlaceholder || "Enter your comment"}
                  value={kommentar}
                  onChange={(e) => setKommentar(e.target.value)}
                />
                <FaListAlt className="cr-customtext-icon" onClick={() => handleCustomTextClick(customTexts, setKommentar)} />
              </div>
            </div>
            <div className="cr-buttons">
              <button className="cr-button cr-back-button" onClick={handleBack}>{translations.back || 'Back'}</button>
              <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>{translations.save || 'Save'}</button>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={translations.selectText || 'Select Custom Text'}
        className="cr-modal"
        overlayClassName="cr-overlay"
      >
        <h2>{translations.selectText || 'Select a text'}</h2>
        <ul className="cr-modal-list">
          {modalTexts.map((text, index) => (
            <li key={index} onClick={() => handleTextSelect(text)} className="cr-modal-item">
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsModalOpen(false)} className="cr-button cr-cancel-button">{translations.close || 'Close'}</button>
      </Modal>
    </MainLayout>
  );
};

export default Comment;

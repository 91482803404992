// GoogleAutocomplete.js
import React, { useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';

const GoogleAutocomplete = ({ setFormData, formData }) => {
  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place) {
      const addressComponents = place.address_components;
      const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
      const postalCode = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
      const city = addressComponents.find(comp => comp.types.includes('postal_town'))?.long_name || 
                   addressComponents.find(comp => comp.types.includes('locality'))?.long_name || '';

      setFormData((prevData) => ({
        ...prevData,
        streetAddress: `${route} ${streetNumber}`,
        postal: postalCode,
        city: city
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <Autocomplete
      onLoad={(ref) => (autocompleteRef.current = ref)}
      onPlaceChanged={handlePlaceChanged}
      options={{ componentRestrictions: { country: 'no' } }}
    >
      <input
        type="text"
        name="streetAddress"
        placeholder="Enter street address"
        value={formData.streetAddress}
        onChange={handleChange}
        className="nwo-input"
      />
    </Autocomplete>
  );
};

export default GoogleAutocomplete;

import React from 'react';
import './AddressCard.css';

const AddressCard = ({ address, distances, handleCardClick, showDistance }) => {
  const getBadgeColor = (priColor) => {
    switch (priColor) {
      case 1:
        return 'badge-red';
      case 2:
        return 'badge-yellow';
      case 3:
        return 'badge-green';
      default:
        return '';
    }
  };

  return (
    <div className="address-card-wrapper" onClick={() => handleCardClick(address.behandlingsadr_id)}>
      <div className="address-card">
        <div className={`ac-badge ${getBadgeColor(address.pri_color)}`}>
          {address.ku}/{address.kontroller}
        </div>
        <p><strong>{address.navn}</strong></p>
        <p>{address.behandlingsadr}</p>
        <p>{address.postnr} {address.poststed}</p>
        <p>{address.behandling}</p>
        {showDistance && distances[address.behandlingsadr_id] && (
          <div className="distance-info">
            <p>{distances[address.behandlingsadr_id].distance} - {distances[address.behandlingsadr_id].duration}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressCard;

import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import './CustomerOverviewEditCustomer.css'; // CSS filen for denne modalen
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

Modal.setAppElement('#root');

const CustomerOverviewEditCustomer = ({ isOpen, onRequestClose, customer, onUpdate }) => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [customerCat, setCustomerCat] = useState('');
  const [customerCategories, setCustomerCategories] = useState([]);
  const [emailError, setEmailError] = useState('');
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    if (customer) {
      setPhone(customer.telefon || '');
      setEmail(customer.epost || '');
      setContactPerson(customer.kontakt || '');
      setCustomerCat(customer.customer_cat_id || '');
    }
  }, [customer]);

  useEffect(() => {
    const fetchCustomerCategories = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCustomerCategory/?token=${token}`);
        if (response.data.status === 'success') {
          setCustomerCategories(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching customer categories:', error);
      }
    };

    fetchCustomerCategories();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handleUpdateCustomer = async () => {
    if (emailError) {
      return;
    }

    const token = localStorage.getItem('token');
    const data = {
      telefon: phone,
      epost: email,
      kontakt: contactPerson,
      customer_cat_id: parseInt(customerCat), // Konverterer customer_cat_id til integer
      kunde_id: customer.kunde_id,
    };

    try {
      const response = await apiClient.post(`${config.apiUrl}/updCustCustOverviewNew/?&token=${token}`, data);
      if (response.data.status === 'success') {
        onUpdate();
        onRequestClose();
      }
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <h2>{translations.editCustomerTitle || 'Edit Customer'}</h2>
      <div className="custom-input-field">
        <label>{translations.contactPersonLabel || 'Contact Person'}</label>
        <input
          type="text"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
          placeholder={translations.contactPersonPlaceholder || 'Enter contact person'}
        />
      </div>
      <div className="custom-input-field">
        <label>{translations.phoneLabel || 'Phone'}</label>
        <input
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder={translations.phonePlaceholder || 'Enter phone number'}
        />
      </div>
      <div className="custom-input-field">
        <label>{translations.emailLabel || 'Email'}</label>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder={translations.emailPlaceholder || 'Enter email address'}
        />
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="custom-input-field">
        <label>{translations.customerCategoryLabel || 'Customer Category'}</label>
        <select
          value={customerCat}
          onChange={(e) => setCustomerCat(parseInt(e.target.value))} // Konverterer verdien til integer
        >
          <option value="" disabled>{translations.selectCustomerCategory || 'Select customer category'}</option>
          {customerCategories.map((category) => (
            <option key={category.customer_cat_id} value={category.customer_cat_id}>
              {category.customer_cat}
            </option>
          ))}
        </select>
      </div>
      <div className="custom-modal-buttons">
        <button className="button upload-button" onClick={handleUpdateCustomer}>
          {translations.submitButton || 'Submit'}
        </button>
        <button className="button cancel-button" onClick={onRequestClose}>
          {translations.cancelButton || 'Cancel'}
        </button>
      </div>
    </Modal>
  );
};

export default CustomerOverviewEditCustomer;

import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext

const SortSelector = ({ sortCriteria, handleSortChange, showOptimalRouteOption }) => {
  const { translations } = useContext(LanguageContext); // Hent oversettelser fra LanguageContext

  return (
    <div className="sort-container">
      <label htmlFor="sort-select">{translations['sortBy'] || 'Sort by:'}</label>
      <select 
        id="sort-select" 
        value={sortCriteria} 
        onChange={(e) => handleSortChange(e.target.value)}
      >
        <option value="predefined">{translations['predefined'] || 'Predefined'}</option>
        <option value="priority">{translations['priority'] || 'Priority'}</option>
        <option value="postnr">{translations['postcode'] || 'Postcode'}</option>
        {showOptimalRouteOption && (
          <option value="optimal">{translations['optimalRoute'] || 'Optimal Route'}</option>
        )}
      </select>
    </div>
  );
};

export default SortSelector;

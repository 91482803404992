import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaListAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const Result = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [behandlingResultat, setBehandlingResultat] = useState('');
  const [customTexts, setCustomTexts] = useState([]);
  const [initialBehandlingResultat, setInitialBehandlingResultat] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTexts, setModalTexts] = useState([]);
  const [setTextFunction, setSetTextFunction] = useState(() => () => {});
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getTreatResultOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.length > 0) {
          setBehandlingResultat(response.data.data[0].behandling_resultat || '');
          setInitialBehandlingResultat(response.data.data[0].behandling_resultat || '');
        }
      } catch (error) {
        console.error('Error fetching treat result oppdrag:', error);
        notify('error', translations.fetchResultError || 'Failed to fetch treatment result. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCustomTexts = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCustomtext/5/?token=${token}`);
        if (response.data && response.data.data) {
          setCustomTexts(response.data.data.map(item => item.text));
        }
      } catch (error) {
        console.error('Error fetching custom texts:', error);
        notify('error', translations.fetchCustomTextsError || 'Failed to fetch custom texts. Please try again.');
      }
    };

    fetchData();
    fetchCustomTexts();
  }, [oppdrag_id, token, notify, translations]);

  const handleBack = async () => {
    if (behandlingResultat !== initialBehandlingResultat) {
      const confirmSave = window.confirm(translations.unsavedChangesConfirm || 'You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/neighbors/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving treatment result:', error);
          notify('error', translations.saveResultError || 'Failed to save treatment result. Please try again.');
        }
      } else {
        navigate(`/legacy/neighbors/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/neighbors/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    if (behandlingResultat.trim() === '') {
      notify('error', translations.enterResultError || 'Please enter the treatment result.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/updTreatResult/?token=${token}`, {
        oppdrag_id,
        behandling_resultat: behandlingResultat,
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/risk/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', translations.resultSaved || 'Treatment result saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/risk/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error saving treatment result:', error);
      notify('error', translations.saveResultError || 'Failed to save treatment result. Please try again.');
    }
  };

  const handleCustomTextClick = (texts, setTextFunc) => {
    setModalTexts(texts);
    setSetTextFunction(() => setTextFunc);
    setIsModalOpen(true);
  };

  const handleTextSelect = (text) => {
    setTextFunction((prev) => `${prev}\n${text}`);
    setIsModalOpen(false);
  };

  const getTextAreaClass = (value) => {
    if (value) {
      return value.trim() === '' ? 'ml-mandatory' : 'ml-filled';
    }
    return 'ml-mandatory';
  };

  return (
    <MainLayout title={translations.resultTitle || "Treatment Result"}>
      <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <div className="cr-box">
            <h2 className="cr-heading">{translations.enterResult || 'Enter the treatment result:'}</h2>
            <div className="cr-textarea-container">
              <textarea
                className={`cr-textarea ${getTextAreaClass(behandlingResultat)}`}
                placeholder={translations.enterResultPlaceholder || "Enter the treatment result"}
                value={behandlingResultat}
                onChange={(e) => setBehandlingResultat(e.target.value)}
              />
              <FaListAlt className="cr-customtext-icon" onClick={() => handleCustomTextClick(customTexts, setBehandlingResultat)} />
            </div>
          </div>
        )}
        <div className="cr-buttons">
          <button className="cr-button cr-back-button" onClick={handleBack}>{translations.backButton || 'Back'}</button>
          <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>{translations.saveButton || 'Save'}</button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={translations.selectCustomText || "Select Custom Text"}
        className="cr-modal"
        overlayClassName="cr-overlay"
      >
        <h2>{translations.selectText || 'Select a text'}</h2>
        <ul className="cr-modal-list">
          {modalTexts.map((text, index) => (
            <li key={index} onClick={() => handleTextSelect(text)} className="cr-modal-item">
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => setIsModalOpen(false)} className="cr-button cr-cancel-button">{translations.closeButton || 'Close'}</button>
      </Modal>
    </MainLayout>
  );
};

export default Result;

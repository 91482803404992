import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import './ServiceAddressUpdateInfo.css';
import { useToast } from '../contexts/ToastContext';
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext

const ServiceAddressUpdateInfo = ({ isOpen, onClose, currentInfo, emailReport, emailReportCopy, behandlingsadrId, token, onSave }) => {
  const { translations } = useContext(LanguageContext); // Hent oversettelser fra LanguageContext
  const [notes, setNotes] = useState('');
  const [email, setEmail] = useState('');
  const [emailCc, setEmailCc] = useState('');

  useEffect(() => {
    if (currentInfo) setNotes(currentInfo);
    if (emailReport) setEmail(emailReport);
    if (emailReportCopy) setEmailCc(emailReportCopy);
  }, [currentInfo, emailReport, emailReportCopy]);

  const notify = useToast();

  const handleSave = async () => {
    try {
      const response = await apiClient.post(`${config.apiUrl}/updServiceAddressNew/?token=${token}`, {
        notat: notes,
        mail_to: email,
        mail_cc: emailCc,
        behandlingsadr_id: behandlingsadrId,
      });

      if (response.data.status === 'success') {
        notify('success', translations['infoUpdatedSuccessfully'] || 'Information updated successfully');
        onSave();
        onClose();
      } else {
        notify('error', translations['updateFailed'] || 'Failed to update information');
      }
    } catch (error) {
      console.error('Error updating information:', error);
      notify('error', translations['updateError'] || 'An error occurred while updating information');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={translations['updateServiceAddressInfo'] || 'Update Service Address Information'}
      className="update-info-modal"
      overlayClassName="update-info-overlay"
    >
      <h2>{translations['updateServiceAddressInfo'] || 'Update Service Address Information'}</h2>
      <div className="input-field">
        <label htmlFor="email">{translations['emailReport'] || 'Email (report)'}</label>
        <input
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={translations['enterReportEmail'] || 'Enter report email'}
          className="update-info-input"
        />
      </div>
      <div className="input-field">
        <label htmlFor="emailCc">{translations['emailReportCopy'] || 'Email (Report copy)'}</label>
        <input
          id="emailCc"
          name="emailCc"
          type="email"
          value={emailCc}
          onChange={(e) => setEmailCc(e.target.value)}
          placeholder={translations['enterReportCopyEmail'] || 'Enter report copy email'}
          className="update-info-input"
        />
      </div>
      <div className="input-field">
        <label htmlFor="notes">{translations['otherInfo'] || 'Other Information'}</label>
        <textarea
          id="notes"
          name="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder={translations['writeNotes'] || 'Write notes about the service address here'}
          className="update-info-textarea"
        />
      </div>
      <div className="update-info-buttons">
        <button className="button cancel-button" onClick={onClose}>
          {translations['cancel'] || 'Cancel'}
        </button>
        <button className="button save-button" onClick={handleSave}>
          {translations['save'] || 'Save'}
        </button>
      </div>
    </Modal>
  );
};

export default ServiceAddressUpdateInfo;

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient';
import { Loader } from '@googlemaps/js-api-loader';
import config from '../config';
import { useToast } from '../contexts/ToastContext';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext
import './ServiceAddressNew.css';

const ServiceAddressNew = () => {
  const { avtaleId, kundeId, behandlingsadrId } = useParams();
  const navigate = useNavigate();
  const notify = useToast();
  const { translations } = useContext(LanguageContext); // Bruk translations fra LanguageContext
  const [formData, setFormData] = useState({
    routeArea: '',
    email: '',
    emailCc: '',
    address: '',
    aptNo: '',
    city: '',
    zip: '',
    otherInfo: ''
  });

  const [routeAreas, setRouteAreas] = useState([]);
  const [errors, setErrors] = useState({});
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const fetchRouteAreas = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getRouteAreas?token=${token}`);
        if (response.data.status === 'success') {
          setRouteAreas(response.data.data);
        } else {
          notify('error', translations.failedToFetchRouteAreas || 'Failed to fetch route areas.');
        }
      } catch (error) {
        notify('error', translations.errorFetchingRouteAreas || 'An error occurred while fetching route areas.');
      }
    };

    fetchRouteAreas();
  }, [notify, translations]);

  useEffect(() => {
    if (behandlingsadrId) {
      const fetchServiceAddress = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await apiClient.get(`${config.apiUrl}/getServiceAddressNewAgrmnt/${behandlingsadrId}?token=${token}`);
          if (response.data.status === 'success') {
            const address = response.data.data[0];
            setFormData({
              routeArea: address.rute_omrade_id,
              email: address.mail_to,
              emailCc: address.mail_cc,
              address: address.behandlingsadr,
              aptNo: address.unit_number,
              city: address.city,
              zip: address.postnr,
              otherInfo: address.notat
            });
          } else {
            notify('error', translations.failedToFetchServiceAddress || 'Failed to fetch service address.');
          }
        } catch (error) {
          notify('error', translations.errorFetchingServiceAddress || 'An error occurred while fetching service address.');
        }
      };

      fetchServiceAddress();
    }
  }, [behandlingsadrId, notify, translations]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then(() => {
      if (window.google && window.google.maps && window.google.maps.places) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          autocompleteRef.current,
          { types: ['address'] }
        );

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (!place.address_components) {
            notify('error', translations.noAddressComponents || 'No address components found');
            return;
          }

          const addressComponents = place.address_components;
          const address = {
            streetNumber: '',
            route: '',
            postal: '',
            city: ''
          };

          addressComponents.forEach(component => {
            const types = component.types;
            if (types.includes('street_number')) {
              address.streetNumber = component.long_name;
            }
            if (types.includes('route')) {
              address.route = component.long_name;
            }
            if (types.includes('postal_code')) {
              address.postal = component.long_name;
            }
            if (types.includes('locality')) {
              address.city = component.long_name;
            }
            if (types.includes('postal_town')) {
              address.city = component.long_name;
            }
          });

          setFormData((prevData) => ({
            ...prevData,
            address: `${address.route} ${address.streetNumber}`.trim(),
            zip: address.postal,
            city: address.city,
          }));
        });
      }
    }).catch((error) => {
      console.error('Error loading Google Maps script:', error);
      notify('error', translations.errorLoadingGoogleMaps || 'Error loading Google Maps script');
    });
  }, [notify, translations]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'email' || name === 'emailCc') {
      if (value && !isEmailValid(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: translations.invalidEmailAddress || 'Invalid email address',
        }));
      } else {
        setErrors((prevErrors) => {
          const { [name]: removedError, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () => {
    const { routeArea, address, city, zip, email, emailCc } = formData;
    return (
      routeArea &&
      address &&
      city &&
      zip &&
      (email === '' || isEmailValid(email)) &&
      (emailCc === '' || isEmailValid(emailCc))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const payload = {
      behandlingsadr: formData.address,
      avtale_id: avtaleId,
      postnr: formData.zip,
      rute_omrade_id: formData.routeArea,
      rapport_beh_id: null, 
      mail_to: formData.email,
      mail_cc: formData.emailCc,
      notat: formData.otherInfo,
      city: formData.city,
      unit_number: formData.aptNo,
    };

    try {
      if (behandlingsadrId) {
        const response = await apiClient.post(
          `${config.apiUrl}/updServiceAddressNewAgrmnt?token=${token}`,
          { ...payload, behandlingsadr_id: behandlingsadrId }
        );

        if (response.data.status === 'success') {
          notify('success', translations.serviceAddressUpdated || 'Service address updated successfully!');
          navigate(`/agreement-new-treat/${avtaleId}/${kundeId}/${behandlingsadrId}`);
        } else {
          notify('error', translations.failedToUpdateServiceAddress || 'Failed to update service address.');
        }
      } else {
        const response = await apiClient.post(
          `${config.apiUrl}/addLocationNew?token=${token}`,
          payload
        );

        if (response.data.status === 'success') {
          notify('success', translations.serviceAddressAdded || 'Service address added successfully!');
          navigate(`/agreement-new-treat/${avtaleId}/${kundeId}/${response.data.behandlingsadr_id}`);
        } else {
          notify('error', translations.failedToAddServiceAddress || 'Failed to add service address.');
        }
      }
    } catch (error) {
      notify('error', translations.errorSavingServiceAddress || 'An error occurred while saving the service address.');
    }
  };

  const getInputClass = (name) => {
    const value = formData[name];
    return value ? 'newsa-input-field filled' : 'newsa-input-field mandatory';
  };

  const handleCopyCustomerInfo = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCopyCustAddress/${kundeId}?token=${token}`);
      if (response.data.status === 'success') {
        const customerInfo = response.data.data[0];
        if (customerInfo) {
          setFormData((prevData) => ({
            ...prevData,
            email: customerInfo.epost || '',
            emailCc: customerInfo.epost_kopi || '',
            address: customerInfo.adresse || '',
            zip: customerInfo.postnr || '',
            city: customerInfo.city || '',
            aptNo: customerInfo.unit_number || '',
          }));
          notify('success', translations.customerInfoCopied || 'Customer information copied successfully!');
        } else {
          notify('error', translations.customerInfoMissing || 'Customer information is missing or incomplete.');
        }
      } else {
        notify('error', translations.failedToFetchCustomerInfo || 'Failed to fetch customer information.');
      }
    } catch (error) {
      notify('error', translations.errorFetchingCustomerInfo || 'An error occurred while fetching customer information.');
    }
  };
  
  return (
    <MainLayout title={translations.newServiceAddress || 'New Service Address'}>
      <div className="service-address-new-container">
        <form className="service-address-new-form" onSubmit={handleSubmit}>
          <div className="service-address-new-form-group">
            <label>{translations.routeArea || 'Route Area'}</label>
            <div className="input-with-button">
              <select
                name="routeArea"
                value={formData.routeArea}
                onChange={handleChange}
                className={getInputClass('routeArea')}
                required
              >
                <option value="">{translations.choose || 'Choose'}</option>
                {routeAreas.map((area) => (
                  <option key={area.rute_omrade_id} value={area.rute_omrade_id}>
                    {area.omrade}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="copy-button"
                onClick={handleCopyCustomerInfo}
              >
                {translations.copyCustomerInfo || 'Copy customer info'}
              </button>
            </div>
          </div>

          <div className="service-address-new-form-group">
            <label>{translations.emailReport || 'Email Address (Report)'}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="newsa-input-field"
              placeholder={translations.emailReportPlaceholder || 'Please provide the email for the report recipient'}
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>

          <div className="service-address-new-form-group">
            <label>{translations.emailReportCopy || 'Email Address (Report Copy)'}</label>
            <input
              type="email"
              name="emailCc"
              value={formData.emailCc}
              onChange={handleChange}
              className="newsa-input-field"
              placeholder={translations.emailReportCopyPlaceholder || 'Please enter optional email for the recipient of a report copy'}
            />
            {errors.emailCc && <div className="error-message">{errors.emailCc}</div>}
          </div>

          <div className="service-address-new-form-group inline-group">
            <div className="service-address-new-form-group street-address">
              <label>{translations.streetAddress || 'Street Address'}</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className={`newsa-input-field ${getInputClass('address')}`}
                required
                ref={autocompleteRef}
                placeholder={translations.enterStreetAddress || 'Enter street address'}
              />
            </div>
            <div className="service-address-new-form-group apt-no">
              <label>{translations.aptNo || 'Apt No'}</label>
              <input
                type="text"
                name="aptNo"
                value={formData.aptNo}
                onChange={handleChange}
                className="newsa-input-field"
                placeholder={translations.enterAptNo || 'Enter apt number (Optional)'}
              />
            </div>
          </div>

          <div className="service-address-new-form-group inline-group">
            <div className="service-address-new-form-group postal">
              <label>{translations.postal || 'Postal/Zip'}</label>
              <input
                type="text"
                name="zip"
                value={formData.zip}
                onChange={handleChange}
                className={`newsa-input-field ${getInputClass('zip')}`}
                required
                placeholder={translations.enterPostalCode || 'Enter postal/zip code'}
              />
            </div>
            <div className="service-address-new-form-group city">
              <label>{translations.city || 'City'}</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className={`newsa-input-field ${getInputClass('city')}`}
                required
                placeholder={translations.enterCity || 'Enter city'}
              />
            </div>
          </div>

          <div className="service-address-new-form-group">
            <label>{translations.otherInfo || 'Other Information'}</label>
            <textarea
              name="otherInfo"
              value={formData.otherInfo}
              onChange={handleChange}
              className="newsa-input-field"
              placeholder={translations.otherInfoPlaceholder || 'Please provide any notes regarding the service address here'}
            />
          </div>

          <div className="form-actions">
            <button type="submit" className="service-address-save-button" disabled={!isFormValid()}>
              {translations.save || 'Save'}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default ServiceAddressNew;

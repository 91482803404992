// src/contexts/ToastContext.js
// src/contexts/ToastContext.js
import React, { createContext, useContext, useRef, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Opprett ToastContext
const ToastContext = createContext();

// Tilpassede Toast-typer for konsistens
const ToastTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};

// Tilpasset hook for å bruke ToastContext
export const useToast = () => useContext(ToastContext);

// ToastProvider-komponenten som pakker inn hele applikasjonen
export const ToastProvider = ({ children }) => {
  const containerRendered = useRef(false);
  let lastMessage = '';

  // Sjekk om ToastContainer er rendert flere ganger
  useEffect(() => {
    if (containerRendered.current) {
      console.warn("Multiple ToastContainer instances detected.");
    } else {
      containerRendered.current = true;
    }
  }, []);

  // Funksjon for å vise toast-notifikasjoner
  const notify = (type, message) => {
    if (!message) {
      console.error("Toast message is missing");
      return;
    }

    // Hindre dupliserte toast-meldinger
    if (message === lastMessage) {
      console.warn("Duplicate toast message detected. Skipping.");
      return;
    }

    lastMessage = message;

    // Sjekk for gyldige toast-typer
    if (!Object.values(ToastTypes).includes(type)) {
      console.warn(`Invalid toast type: ${type}. Falling back to default.`);
    }

    // Vis toast basert på type
    switch (type) {
      case ToastTypes.SUCCESS:
        toast.success(message);
        break;
      case ToastTypes.ERROR:
        toast.error(message);
        break;
      case ToastTypes.INFO:
        toast.info(message);
        break;
      case ToastTypes.WARNING:
        toast.warn(message);
        break;
      default:
        toast(message);
    }
  };

  return (
    <ToastContext.Provider value={notify}>
      {children}
      {/* ToastContainer sørger for at toastene vises på skjermen */}
      <ToastContainer
        position="top-right"
        autoClose={2000} // Lukkes automatisk etter 2000ms (2 sekunder)
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ToastContext.Provider>
  );
};

/**
 * ToastContext provides a simple way to show toast notifications across the application.
 * 
 * Usage:
 * 1. Wrap your application with <ToastProvider>.
 * 2. Use `useToast` hook to get the `notify` function.
 * 3. Call `notify(type, message)` to display a toast.
 * 
 * Available types: 'success', 'error', 'info', 'warning'
 */

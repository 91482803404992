import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import Modal from 'react-modal';
import CustomerOverviewEditCustomer from '../components/CustomerOverviewEditCustomer';
import CustomerOverviewEditAgrmt from '../components/CustomerOverviewEditAgrmt';
import CustomerOverviewAddServiceAddress from '../components/CustomerOverviewAddServiceAddress';
import CustomerOverviewVisitHistory from '../components/CustomerOverviewVisitHistory'; // Importer den nye komponenten
import './CustomerOverview.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext

Modal.setAppElement('#root');

const CustomerOverview = () => {
  const { translations } = useContext(LanguageContext); // Use translations from LanguageContext
  const { kundeId } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditAgrmtModalOpen, setIsEditAgrmtModalOpen] = useState(false);
  const [isAddServiceAddressModalOpen, setIsAddServiceAddressModalOpen] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [expandedAgreements, setExpandedAgreements] = useState([]);
  const [addresses, setAddresses] = useState({});
  const [selectedAgreement, setSelectedAgreement] = useState(null);

  useEffect(() => {
    if (!kundeId) {
      navigate('/customers');
    } else {
      fetchCustomerData(kundeId);
      fetchAgreements(kundeId);
    }
  }, [kundeId, navigate]);

  const fetchCustomerData = async (kundeId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustOverview/${kundeId}/?&token=${token}`);
      if (response.data.status === 'success') {
        setCustomer(response.data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const fetchAgreements = async (kundeId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustomerAllAgreements/${kundeId}/?&token=${token}`);
      if (response.data.status === 'success') {
        setAgreements(response.data.data);
        response.data.data.forEach(agreement => fetchAddresses(agreement.avtale_id));
      }
    } catch (error) {
      console.error('Error fetching agreements:', error);
    }
  };

  const fetchAddresses = async (avtale_id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await apiClient.get(`${config.apiUrl}/getCustomerAllAgreementsBehadr/${avtale_id}/?&token=${token}`);
      if (response.data.status === 'success') {
        setAddresses(prevAddresses => ({
          ...prevAddresses,
          [avtale_id]: response.data.data
        }));
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const toggleAgreement = (id) => {
    setExpandedAgreements((prevExpanded) =>
      prevExpanded.includes(id)
        ? prevExpanded.filter((agreementId) => agreementId !== id)
        : [...prevExpanded, id]
    );
  };

  const navigateToServiceAddress = (behandlingsadr_id) => {
    navigate(`/serviceaddress/${behandlingsadr_id}`, { state: { from: `/customeroverview/${customer.kunde_id}`, fromName: translations['customerOverview'] || 'Customer Overview' } });
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openEditAgrmtModal = (agreement) => {
    setSelectedAgreement(agreement);
    setIsEditAgrmtModalOpen(true);
  };

  const closeEditAgrmtModal = () => {
    setIsEditAgrmtModalOpen(false);
  };

  const openAddServiceAddressModal = (agreement) => {
    setSelectedAgreement(agreement);
    setIsAddServiceAddressModalOpen(true);
  };

  const closeAddServiceAddressModal = () => {
    setIsAddServiceAddressModalOpen(false);
  };

  return (
    <MainLayout title={translations['customerOverview'] || "Customer Overview"}>
      <div className="custom-container">
        {customer ? (
          <div className="custom-card">
            <div className="custom-card-header">
              <div className="custom-card-info">
                <h2>{customer.navn}</h2>
                <p>{customer.adresse}</p>
                <p>{customer.postnr} {customer.poststed}</p>
                <p><strong>{translations['extCustNo'] || 'Ext. Cust No:'}</strong> <span>{customer.kundenr}</span></p>
                <p><strong>{translations['contactPerson'] || 'Contact Person:'}</strong> <span>{customer.kontakt}</span></p>
                <p><strong>{translations['phone'] || 'Phone:'}</strong> <span>{customer.telefon}</span></p>
                <p><strong>{translations['email'] || 'Email:'}</strong> <span>{customer.epost}</span></p>
                <p><strong>{translations['customerCategory'] || 'Customer Category:'}</strong> <span>{customer.customer_cat}</span></p>
              </div>
            </div>
            <button className="button edit-button" onClick={openEditModal}>{translations['edit'] || 'Edit'}</button>
            <button className="button create-agreement-button" onClick={() => navigate(`/agreement-new/${customer.kunde_id}`)}>
              {translations['createAgreement'] || 'Create Agreement'}
            </button>
            
            <div className="custom-accordion-section">
              <button className="custom-accordion-button" onClick={() => toggleAgreement('agreements')}>{translations['agreements'] || 'Agreements'}</button>
              {expandedAgreements.includes('agreements') && (
                <div className="custom-accordion-content">
                  {agreements.map((agreement) => (
                    <div key={agreement.avtale_id} className="custom-accordion-item">
                      <button className="custom-accordion-button" onClick={() => toggleAgreement(agreement.avtale_id)}>
                        {agreement.agrmt_name ? agreement.agrmt_name : `${translations['agreement']} #${agreement.avtale_id}`} - {agreement.dato_start}
                      </button>
                      {expandedAgreements.includes(agreement.avtale_id) && (
                        <div className="custom-accordion-item-content">
                          <p><strong>{translations['status'] || 'Status:'}</strong> {agreement.agrmt_status}</p>
                          <p><strong>{translations['pestTreatment'] || 'Pest/Treatment:'}</strong> {agreement.behandling.join(', ')}</p>
                          <p><strong>{translations['equipmentType'] || 'Equipment Type:'}</strong> {agreement.utstyr.map(item => `${item.utstyr} (${item.antall})`).join(', ')}</p>
                          <p><strong>{translations['agreementName'] || 'Agreement Name:'}</strong> {agreement.agrmt_name}</p>
                          <p><strong>{translations['workDescription'] || 'Work Description:'}</strong> {agreement.work_descr}</p>
                          <p><strong>{translations['notes'] || 'Notes:'}</strong> {agreement.notat}</p>
                          <p><strong>{translations['visitsPerYear'] || 'Number of Visits per Year:'}</strong> {agreement.kontroller}</p>
                          <p><strong>{translations['visitsThisYear'] || 'Number of Visits This Year:'}</strong> {agreement.visits}</p>
                          <div>
                            <button 
                              className="button edit-agreement-button" 
                              onClick={() => openEditAgrmtModal(agreement)}
                            >
                              {translations['editAgreement'] || 'Edit Agreement'}
                            </button>
                            {addresses[agreement.avtale_id] && addresses[agreement.avtale_id].map(address => (
                              <button
                                key={address.behandlingsadr_id}
                                className="custom-address-button"
                                onClick={() => navigateToServiceAddress(address.behandlingsadr_id)}
                              >
                                {address.behandlingsadr}, {address.postnr} {address.poststed}
                              </button>
                            ))}
                            <button className="button add-service-address-button" onClick={() => openAddServiceAddressModal(agreement)}>
                              {translations['addServiceAddress'] || 'Add Service Address'}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            {/* Legg til Visit History Customer-komponenten */}
            <CustomerOverviewVisitHistory 
              kundeId={kundeId} 
              token={localStorage.getItem('token')} 
            />
            
          </div>
        ) : (
          <p>{translations['loading'] || 'Loading...'}</p>
        )}

        <CustomerOverviewEditCustomer
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          customer={customer}
          onUpdate={() => fetchCustomerData(kundeId)}  // Kaller fetchCustomerData med kundeId for å oppdatere dataene
        />

        <CustomerOverviewEditAgrmt
          isOpen={isEditAgrmtModalOpen}
          onRequestClose={closeEditAgrmtModal}
          agreement={selectedAgreement}
          onUpdate={() => fetchAgreements(kundeId)} // Kaller fetchAgreements med kundeId for å oppdatere avtale-dataene
        />

        <CustomerOverviewAddServiceAddress
          isOpen={isAddServiceAddressModalOpen}
          onRequestClose={closeAddServiceAddressModal}
          agreement={selectedAgreement}  // Passer den valgte avtalen til modalen
          onUpdate={() => fetchAgreements(kundeId)} // Oppdaterer avtale-dataene når en ny serviceadresse legges til
        />

      </div>
    </MainLayout>
  );
};

export default CustomerOverview;

import React, { useState, useEffect, useContext } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import '../pages/CustomerOverview.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const CustomerOverviewVisitHistory = ({ kundeId, token }) => {
  const [isVisitHistoryVisible, setIsVisitHistoryVisible] = useState(false);
  const [visitHistory, setVisitHistory] = useState([]);
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchVisitHistory = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/getCustOverviewAssignmnt/${kundeId}/?token=${token}`);
        if (response.data.status === 'success') {
          setVisitHistory(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching visit history:', error);
      }
    };

    if (isVisitHistoryVisible) {
      fetchVisitHistory();
    }
  }, [isVisitHistoryVisible, kundeId, token]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nb-NO', options);
  };

  const handleDownloadReport = (oppdrag_id) => {
    const url = `${config.apiUrl}/getFileReport/${oppdrag_id}?token=${token}`;
    window.open(url, '_blank');
  };

  return (
    <div className="expandable-section">
      <button className="expand-button" onClick={() => setIsVisitHistoryVisible(!isVisitHistoryVisible)}>
        {translations.visitHistory || 'Visit History'}
      </button>
      {isVisitHistoryVisible && (
        <div className="expandable-content">
          {visitHistory.length > 0 ? (
            visitHistory.map((visit) => (
              <div key={visit.oppdrag_id} className="visit-card">
                <div className="visit-card-header">
                  <p><strong>{translations.assignmentNo || 'Assignment No'} #{visit.oppdrag_id}</strong></p>
                  <div className="date-align-right">{formatDate(visit.dato_start)}</div>
                </div>
                <div className="visit-card-body">
                  <div>
                    <p><strong>{visit.navn}</strong></p>
                    <p>{visit.utfort}</p>
                  </div>
                  <FaFilePdf className="pdf-icon" onClick={() => handleDownloadReport(visit.oppdrag_id)} />
                </div>
              </div>
            ))
          ) : (
            <p>{translations.noRecords || 'No Records'}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerOverviewVisitHistory;

// App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import Login from './pages/Login';
import MainMenu from './pages/MainMenu';
import Calendar from './pages/Calendar';
import MyProfile from './pages/MyProfile';
import DrivingRoutes from './pages/DrivingRoutes';
import Addresses from './pages/Addresses'; // Importer Addresses.js
import Agreements from './pages/Agreements';
import Customers from './pages/Customers';
import SearchAddress from './pages/SearchAddress';
import NewInspection from './pages/NewInspection';
import MyCompleteTasks from './pages/MyCompleteTasks';
import IncompleteReports from './pages/IncompleteReports';
import FutureJobs from './pages/FutureJobs';
import NewWorkOrder from './pages/NewWorkOrder';
import ServiceAddress from './pages/ServiceAddress';
import CustomerOverview from './pages/CustomerOverview';
import CustomerNew from './pages/CustomerNew';
import AgreementNew from './pages/AgreementNew';
import ServiceAddressNew from './pages/ServiceAddressNew';
import AgreementNewTreat from './pages/AgreementNewTreat'; // Importer ny side
import AgreementNewProd from './pages/AgreementNewProd';
import AgreementNewPreview from './pages/AgreementNewPreview';
import CreateReport from './pages/legacy/CreateReport';
import VisitType from './pages/legacy/VisitType';
import CheckType from './pages/legacy/CheckType';
import PestActivity from './pages/legacy/PestActivity';
import WhatWeDone from './pages/legacy/WhatWeDone';
import Method from './pages/legacy/Method';
import Neighbors from './pages/legacy/Neighbors';
import Result from './pages/legacy/Result';
import Risk from './pages/legacy/Risk';
import Products from './pages/legacy/Products';
import Comment from './pages/legacy/Comment';
import Photos from './pages/legacy/Photos';
import Preview from './pages/legacy/Preview';
import EffortsSummary from './pages/legacy/EffortsSummary';
import InspectionDetails from './pages/InspectionDetails';

import './App.css';

const App = () => {
  return (
    
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/mainmenu" element={<MainMenu />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/driving-routes" element={<DrivingRoutes />} />
        <Route path="/addresses/:rute_omrade_id" element={<Addresses />} /> {/* Ny rute */}
        <Route path="/agreements" element={<Agreements />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/search-address" element={<SearchAddress />} />
        <Route path="/new-inspection/:inspectionId?" element={<NewInspection />} />
        <Route path="/inspectiondetails/:inspectionId" element={<InspectionDetails />} />
        <Route path="/my-complete-tasks" element={<MyCompleteTasks />} />
        <Route path="/incomplete-reports" element={<IncompleteReports />} />
        <Route path="/future-jobs" element={<FutureJobs />} />
        <Route path="/new-work-order" element={<NewWorkOrder />} />
        <Route path="/serviceaddress/:behandlingsadrId" element={<ServiceAddress />} /> {/* Updated route */}
        <Route path="/customeroverview/:kundeId" element={<CustomerOverview />} /> {/* Updated route */}
        <Route path="/customernew" element={<CustomerNew />} />
        <Route path="/agreement-new/:kundeId/:avtaleId?/:behandlingsadrId?" element={<AgreementNew />} /> {/* Valgfri avtaleId */}
        <Route path="/serviceaddress-new/:kundeId/:avtaleId/:behandlingsadrId?" element={<ServiceAddressNew />} /> {/* Ny rute */}
        <Route path="/agreement-new-treat/:avtaleId/:kundeId/:behandlingsadrId" element={<AgreementNewTreat />} /> {/* Ny rute */}
        <Route path="/agreement-new-prod/:kundeId/:avtaleId/:behandlingsadrId" element={<AgreementNewProd />} />
        <Route path="/agreement-new-preview/:kundeId/:avtaleId/:behandlingsadrId" element={<AgreementNewPreview />} />
        <Route path="/serviceaddress-new/:kundeId/:avtaleId" element={<ServiceAddressNew />} />
        <Route path="/legacy/createreport/:behandlingsadrId/:oppdrag_id" element={<CreateReport />} />
        <Route path="/legacy/visittype/:behandlingsadrId/:oppdrag_id" element={<VisitType />} />
        <Route path="/legacy/checktype/:behandlingsadrId/:oppdrag_id" element={<CheckType />} />
        <Route path="/legacy/pestactivity/:behandlingsadrId/:oppdrag_id" element={<PestActivity />} />
        <Route path="/legacy/whatwedone/:behandlingsadrId/:oppdrag_id" element={<WhatWeDone />} />
        <Route path="/legacy/method/:behandlingsadrId/:oppdrag_id" element={<Method />} />
        <Route path="/legacy/neighbors/:behandlingsadrId/:oppdrag_id" element={<Neighbors />} />
        <Route path="/legacy/result/:behandlingsadrId/:oppdrag_id" element={<Result />} />
        <Route path="/legacy/risk/:behandlingsadrId/:oppdrag_id" element={<Risk />} />
        <Route path="/legacy/products/:behandlingsadrId/:oppdrag_id" element={<Products />} />
        <Route path="/legacy/comment/:behandlingsadrId/:oppdrag_id" element={<Comment />} />
        <Route path="/legacy/photos/:behandlingsadrId/:oppdrag_id" element={<Photos />} />
        <Route path="/legacy/preview/:behandlingsadrId/:oppdrag_id" element={<Preview />} />
        <Route path="/legacy/effortssummary/:behandlingsadrId/:oppdrag_id" element={<EffortsSummary />} />
      </Routes>
    </LanguageProvider>
    
  );
};

export default App;

import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import { LoadScriptNext } from '@react-google-maps/api';
import GoogleAutocomplete from '../components/GoogleAutocomplete';
import './CustomerOverviewAddServiceAddress.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const libraries = ['places'];

const CustomerOverviewAddServiceAddress = ({ isOpen, onRequestClose, agreement, onUpdate }) => {
  const [formData, setFormData] = useState({
    routeArea: '',
    email: '',
    emailCc: '',
    streetAddress: '',
    aptNo: '',
    city: '',
    postal: '',
    otherInfo: ''
  });

  const [routeAreas, setRouteAreas] = useState([]);
  const [errors, setErrors] = useState({});
  const { translations } = useContext(LanguageContext); // Bruk oversettelser

  useEffect(() => {
    const fetchRouteAreas = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getRouteAreas?token=${token}`);
        if (response.data.status === 'success') {
          setRouteAreas(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching route areas:', error);
      }
    };

    fetchRouteAreas();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'email' || name === 'emailCc') {
      if (value && !isEmailValid(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: translations.invalidEmailAddress || 'Invalid email address',
        }));
      } else {
        setErrors((prevErrors) => {
          const { [name]: removedError, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () => {
    const { routeArea, streetAddress, city, postal, email, emailCc } = formData;
    return (
      routeArea &&
      streetAddress &&
      city &&
      postal &&
      (email === '' || isEmailValid(email)) &&
      (emailCc === '' || isEmailValid(emailCc))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const payload = {
      behandlingsadr: formData.streetAddress,
      avtale_id: agreement.avtale_id,
      postnr: formData.postal,
      rute_omrade_id: formData.routeArea,
      mail_to: formData.email,
      mail_cc: formData.emailCc,
      notat: formData.otherInfo,
      city: formData.city,
      unit_number: formData.aptNo,
      rapport_beh_id: 2, // Legger til dette feltet i payload
    };

    try {
      const response = await apiClient.post(
        `${config.apiUrl}/addLocationNew?token=${token}`,
        payload
      );

      if (response.data.status === 'success') {
        onUpdate();
        onRequestClose();
      } else {
        console.error('Failed to add service address.');
      }
    } catch (error) {
      console.error('An error occurred while saving the service address.', error);
    }
  };

  return (
    <LoadScriptNext
      googleMapsApiKey={config.googleMapsApiKey}
      libraries={libraries}
    >
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <h2>{translations.addServiceAddressTitle || 'Add Service Address'}</h2>
        <form className="service-address-new-form" onSubmit={handleSubmit}>
          <div className="input-field">
            <label htmlFor="routeArea">{translations.routeAreaLabel || 'Route Area'}</label>
            <select
              id="routeArea"
              name="routeArea"
              value={formData.routeArea}
              onChange={handleChange}
            >
              <option value="" disabled>{translations.selectRouteAreaPlaceholder || 'Select Route Area'}</option>
              {routeAreas.map((area) => (
                <option key={area.rute_omrade_id} value={area.rute_omrade_id}>
                  {area.omrade}
                </option>
              ))}
            </select>
          </div>
          <div className="input-field">
            <label htmlFor="email">{translations.emailLabel || 'Email (report)'}</label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={translations.emailPlaceholder || 'Please provide the email for the report recipient'}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="input-field">
            <label htmlFor="emailCc">{translations.emailCcLabel || 'Email (Report copy)'}</label>
            <input
              id="emailCc"
              name="emailCc"
              type="email"
              value={formData.emailCc}
              onChange={handleChange}
              placeholder={translations.emailCcPlaceholder || 'Please enter optional email for the recipient of a report copy'}
            />
            {errors.emailCc && <p className="error">{errors.emailCc}</p>}
          </div>
          <div className="input-field">
            <label htmlFor="address">{translations.addressLabel || 'Address'}</label>
            <GoogleAutocomplete 
              setFormData={setFormData} 
              formData={formData} 
              placeholder={translations.streetAddressPlaceholder || 'Enter street address'}
            />
          </div>
          <div className="input-field">
            <label htmlFor="aptNo">{translations.aptNoLabel || 'Apt No.'}</label>
            <input
              id="aptNo"
              name="aptNo"
              type="text"
              value={formData.aptNo}
              onChange={handleChange}
              placeholder={translations.aptNoPlaceholder || 'Enter apt number (Optional)'}
            />
          </div>
          <div className="input-field">
            <label htmlFor="city">{translations.cityLabel || 'City'}</label>
            <input
              id="city"
              name="city"
              type="text"
              value={formData.city}
              onChange={handleChange}
              placeholder={translations.cityPlaceholder || 'Enter city'}
            />
          </div>
          <div className="input-field">
            <label htmlFor="postal">{translations.zipCodeLabel || 'ZIP Code'}</label>
            <input
              id="postal"
              name="postal"
              type="text"
              value={formData.postal}
              onChange={handleChange}
              placeholder={translations.zipCodePlaceholder || 'Enter postal/zip code'}
            />
          </div>
          <div className="input-field">
            <label htmlFor="otherInfo">{translations.otherInfoLabel || 'Other Information'}</label>
            <textarea
              id="otherInfo"
              name="otherInfo"
              value={formData.otherInfo}
              onChange={handleChange}
              placeholder={translations.otherInfoPlaceholder || 'Please provide any notes regarding the service address here'}
            />
          </div>
          <div className="form-buttons"> 
            <button type="button" className="button cancel-button" onClick={onRequestClose}>
              {translations.cancelButton || 'Cancel'}
            </button>
            <button type="submit" className="button save-button" disabled={!isFormValid()}>
              {translations.saveButton || 'Save'}
            </button>
          </div>
        </form>
      </Modal>
    </LoadScriptNext>
  );
};

export default CustomerOverviewAddServiceAddress;

// src/hooks/useUpdateReportProgress.js
// Denne oppdaterer status på oppdrag. Mottar behandlingsadr_id, oppdrag_id og neste side det skal navigeres til.
// Kaster en feilmelding om brukeren har gjort en "ulovlig navigering" som medfører undefined verdier
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';

const useUpdateReportProgress = () => {
  const updateOppdragProgress = async (token, oppdrag_id, behandlingsadrId, path, navigate) => {
    if (behandlingsadrId === undefined || behandlingsadrId === null || oppdrag_id === undefined || oppdrag_id === null) {
      toast.error('Invalid parameters for updating progress. BehandlingsadrId or oppdrag_id is undefined or null.');
      navigate('/mainmenu');
      return false;
    }

    try {
      await axios.post(`${config.apiUrl}/updateOppdragProgress/?token=${token}`, {
        oppdrag_id: oppdrag_id,
        progress: path
      });
      return true;
    } catch (error) {
      console.error('Error updating progress:', error);
      toast.error(`An error occurred while reporting progress on oppdrag_id ${oppdrag_id}. You will be returned to the main menu. Please try to resume reporting from the "incomplete reports" menu.`);
      navigate('/mainmenu');
      return false;
    }
  };

  return { updateOppdragProgress };
};

export default useUpdateReportProgress;

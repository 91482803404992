import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';

const Map = forwardRef(({ initialAddress }, ref) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const [currentLayer, setCurrentLayer] = useState('openstreetmap');
  const isMapLoaded = useRef(false);

  useEffect(() => {
    if (mapContainer.current) {
      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: {
          version: 8,
          sources: {
            'openstreetmap': {
              type: 'raster',
              tiles: [
                'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
                'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
                'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
              ],
              tileSize: 256,
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            },
            'kartverket': {
              type: 'raster',
              tiles: [
                'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}'
              ],
              tileSize: 256,
              attribution: '&copy; <a href="https://www.kartverket.no">Kartverket</a>'
            }
          },
          layers: [
            {
              id: 'osm-layer',
              type: 'raster',
              source: 'openstreetmap',
              minzoom: 0,
              maxzoom: 19
            },
            {
              id: 'kartverket-layer',
              type: 'raster',
              source: 'kartverket',
              minzoom: 0,
              maxzoom: 20,
              layout: { visibility: 'none' } // Skjult som standard
            }
          ]
        },
        center: [10.7522, 59.9139], // Senter koordinater (f.eks. Oslo)
        zoom: 17,
        maxZoom: 20 // Begrens zoomnivået til 19
      });

      mapInstance.current = map;

      map.on('load', () => {
        isMapLoaded.current = true;
        if (initialAddress) {
          geocodeAndCenterMap(initialAddress);
        }
      });

      // Legg til GeolocateControl for å finne brukerens lokasjon
      const geolocateControl = new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      });

      map.addControl(geolocateControl, 'top-right');

      // Legg til NavigationControl for zoom og rotasjon
      const navControl = new maplibregl.NavigationControl();
      map.addControl(navControl, 'top-right');

      // Bruk MaplibreGeocoder med riktig maplibregl-referanse
      const geocoder = new MaplibreGeocoder({
        maplibregl: maplibregl,  // Viktig: Legg til referansen til maplibregl her
        placeholder: 'Search for places',
        forwardGeocode: async (config) => {
          const baseUrl = 'https://nominatim.openstreetmap.org/search';
          const params = new URLSearchParams({
            q: config.query,
            format: 'json',
            addressdetails: 1,
            polygon_geojson: 0
          });
          const response = await fetch(`${baseUrl}?${params}`);
          const data = await response.json();
          return {
            features: data.map((item) => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.lon, item.lat]
              },
              place_name: item.display_name,
              properties: item
            }))
          };
        }
      });

      map.addControl(geocoder, 'top-left');

      // Tilpasset kontroll for å velge lag
      class LayerSwitcherControl {
        onAdd(map) {
          this.map = map;
          this.container = document.createElement('div');
          this.container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
          
          this.button = document.createElement('button');
          this.button.type = 'button';
          this.button.textContent = '🗺️';
          this.button.onclick = () => {
            toggleLayer();
          };

          this.container.appendChild(this.button);
          return this.container;
        }

        onRemove() {
          this.container.parentNode.removeChild(this.container);
          this.map = undefined;
        }
      }

      // Legg til den tilpassede kontrollen
      const layerSwitcherControl = new LayerSwitcherControl();
      map.addControl(layerSwitcherControl, 'top-right');

      return () => map.remove();
    }
  }, []);

  useEffect(() => {
    if (isMapLoaded.current && mapInstance.current) {
      if (currentLayer === 'openstreetmap') {
        mapInstance.current.setLayoutProperty('osm-layer', 'visibility', 'visible');
        mapInstance.current.setLayoutProperty('kartverket-layer', 'visibility', 'none');
      } else if (currentLayer === 'kartverket') {
        mapInstance.current.setLayoutProperty('osm-layer', 'visibility', 'none');
        mapInstance.current.setLayoutProperty('kartverket-layer', 'visibility', 'visible');
      }
    }
  }, [currentLayer]);

  const toggleLayer = () => {
    setCurrentLayer((prevLayer) =>
      prevLayer === 'openstreetmap' ? 'kartverket' : 'openstreetmap'
    );
  };

  const geocodeAndCenterMap = async (address) => {
    const baseUrl = 'https://nominatim.openstreetmap.org/search';
    const params = new URLSearchParams({
      q: address,
      format: 'json',
      addressdetails: 1,
      polygon_geojson: 0
    });
    const response = await fetch(`${baseUrl}?${params}`);
    const data = await response.json();
    if (data && data.length > 0) {
      const { lon, lat } = data[0];
      mapInstance.current.setCenter([parseFloat(lon), parseFloat(lat)]);
    }
  };

  useImperativeHandle(ref, () => ({
    getMap: () => mapInstance.current
  }));

  return <div ref={mapContainer} style={{ width: '80vw', height: '90vh', maxHeight: '700px'}} />;
});

export default Map;

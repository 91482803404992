// src/pages/Calendar.js

import React, { useContext } from 'react';
import MainLayout from '../components/MainLayout';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext

const Calendar = () => {
  const { translations } = useContext(LanguageContext); // Use translations from LanguageContext

  return (
    <MainLayout title={translations['calendar'] || "Calendar"}> {/* Send title prop */}
      {() => (
        <div>
          <h1>{translations['calendarHeading'] || ''}</h1>
        </div>
      )}
    </MainLayout>
  );
};

export default Calendar;

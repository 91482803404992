import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import { FaCamera } from 'react-icons/fa';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import Compressor from 'compressorjs';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; 
import EditImageModal from '../../components/EditImageModal';
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten
import { LanguageContext } from '../../contexts/LanguageContext'; // Importer LanguageContext for oversettelser

const CreateReport = () => {
  const { behandlingsadrId, oppdrag_id } = useParams();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasExistingPhoto, setHasExistingPhoto] = useState(false);
  const [rotation, setRotation] = useState(0); 
  const [showEditModal, setShowEditModal] = useState(false); 
  const fileInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast(); // Bruk useToast hooken
  const { translations } = useContext(LanguageContext); // Legg til oversettelser

  const handleFileOption = (option) => {
    if (option === 'camera') {
      fileInputRef.current.setAttribute('capture', 'environment');
    } else {
      fileInputRef.current.removeAttribute('capture');
    }
    fileInputRef.current.click();
  };

  useEffect(() => {
    const fetchExistingPhoto = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getLocPhotoOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.oppdrag_bilde) {
          setPreviewImage(response.data.data.oppdrag_bilde);
          setHasExistingPhoto(true);
        }
      } catch (error) {
        console.error('Error fetching existing photo:', error);
        notify('error', translations.fetchPhotoError || 'Failed to fetch existing photo. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchExistingPhoto();
  }, [oppdrag_id, token, notify, translations]);

  const handleCancel = async () => {
    try {
      await apiClient.post(`${config.apiUrl}/cancelReport/?token=${token}`, {
        oppdrag_id,
        slettet: 1,
        status_id: 3
      });
      navigate('/mainmenu');
    } catch (error) {
      console.error('Error cancelling report:', error);
      notify('error', translations.cancelReportError || 'Failed to cancel report. Please try again.');
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.6, 
        maxWidth: 1000,
        maxHeight: 1000,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = reader.result;
            setPreviewImage(base64);
            setSelectedFile(base64); // Set the base64 image string here for upload
            setHasExistingPhoto(false);
          };
          reader.readAsDataURL(compressedResult);
        },
        error(err) {
          console.error('Error compressing file:', err);
          notify('error', translations.compressPhotoError || 'Failed to compress photo. Please try again.');
        }
      });
    }
  };

  const handleSaveImage = (editedImage) => {
    setPreviewImage(editedImage); 
    setSelectedFile(editedImage); // Ensure the edited image is ready to be uploaded
  };

  const handleSave = async () => {
    if (!selectedFile && !hasExistingPhoto) {
      notify('error', translations.noPhotoError || 'Please take a picture of the service address.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/addLocPhoto/?token=${token}`, {
        oppdrag_bilde: selectedFile || previewImage,
        oppdrag_id: oppdrag_id
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`, navigate);
      notify('dismiss'); // Unmount any active toasts before navigation
      if (success) {
        notify('success', translations.photoUploadSuccess || 'Photo uploaded successfully.');
        navigate(`/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      notify('error', translations.photoUploadError || 'Failed to upload photo. Please try again.');
    }
  };

  return (
    <MainLayout title={translations.createReportTitle || "Create Report"}>
      <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <>
            <h2 className="cr-heading">{translations.takePicture || 'Take a picture of the service address:'}</h2>
            <div className="cr-box">
              <div className="cr-image-placeholder" onClick={() => handleFileOption('camera')}>
                {previewImage ? (
                  <>
                    <img src={previewImage} alt="Preview" className="cr-preview-image" style={{ transform: `rotate(${rotation}deg)` }} />
                  </>
                ) : (
                  <FaCamera className="cr-camera-icon" />
                )}
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
                          {/* <button
              className="cr-button cr-edit-button"
              onClick={() => setShowEditModal(true)}
              disabled={!previewImage}
            >
              Edit Image
            </button> */}
            </div>
            <div className="cr-buttons">
              <button className="cr-button cr-cancel-button" onClick={handleCancel}>{translations.cancel || 'Cancel'}</button>
              <button className="cr-button cr-save-button" onClick={handleSave}>{translations.save || 'Save'}</button>
            </div>
          </>
        )}
      </div>
      {previewImage && (
        <EditImageModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          imageUrl={previewImage}
          onSaveImage={handleSaveImage}
        />
      )}
    </MainLayout>
  );
};

export default CreateReport;

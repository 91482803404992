import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import usePreviousPage from '../hooks/usePreviousPage';
import { FaHome, FaCalendarAlt, FaUser } from 'react-icons/fa';
import './MainLayout.css';

const MainLayout = ({ children, title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { previousPage } = usePreviousPage();

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (location.state && location.state.message) {
      setMessage(location.state.message);
      const timer = setTimeout(() => setMessage(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [location]);

  const showMessage = (content, type) => {
    setMessage({ content, type });
    const timer = setTimeout(() => setMessage(null), 3000);
    return () => clearTimeout(timer);
  };

  const handleBackClick = () => {
    if (location.pathname === '/mainmenu') {
      return;
    }

    if (location.pathname.includes('/effortssummary')) {
      return;
    }

    if (location.pathname === '/mainmenu' && previousPage.includes('/effortssummary')) {
      navigate('/mainmenu');
      return;
    }

    if (location.pathname.includes('/serviceaddress-new') || location.pathname.includes('/agreement-new') || location.pathname.includes('/agreement-new-treat')) {
      const previousPath = sessionStorage.getItem('previousPath');
      if (previousPath) {
        navigate(previousPath);
        return;
      }
    }

    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className="main-layout" tabIndex="-1">
      <header className="header" tabIndex="-1">
        {location.pathname !== '/mainmenu' && (
          <button onClick={handleBackClick} className="back-button">
            <span aria-label="Back">&lt;</span>
          </button>
        )}
        <h1 className="header-title">{title}</h1>
        {message && (
          <div className={`message ${message.type}`}>
            {message.content}
          </div>
        )}
      </header>
      <main className="content" tabIndex="-1">
        {typeof children === 'function' ? children(showMessage) : children}
      </main>
      <footer className="footer" tabIndex="-1">
        <Link to="/mainmenu" className="footer-button">
          <FaHome className="footer-icon" />
        </Link>
        <Link to="/calendar" className="footer-button">
          <FaCalendarAlt className="footer-icon" />
        </Link>
        <Link to="/myprofile" className="footer-button">
          <FaUser className="footer-icon" />
        </Link>
      </footer>
    </div>
  );
};

export default MainLayout;
